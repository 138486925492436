import {Button, Card, Col, Empty, Row} from "antd";
import {blue} from "@ant-design/colors";
import React from "react";

const EssayCard = ({ essays }) => {
    return (
        <Row gutter={18}>
            {
                essays.length === 0 ? (
                    <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Empty />
                    </div>
                    ) : (
                        essays.map((item) => {
                            return (
                                <Col xs={24} sm={24} md={24} xl={12} xxl={8}>
                                    <Card
                                        title={item.name}
                                        bordered={false}
                                        style={{
                                            marginBottom:"18px"
                                        }}
                                    >
                                        <Row justify="start" align="bottom"  gutter="20">
                                            <Col xs={24}>
                                                {/* <Row><h2>{item.name}</h2></Row> */}
                                                <Row gutter={10}>
                                                    <Col xs={24}><span style={{marginRight:"15px", color: blue.primary}}>写作要求概述：</span></Col>
                                                    <Col xs={24}><span style={{marginRight:"15px"}}>{item.description.length > 200 ? item.description.substring(0, 200)+'...' : item.description}</span></Col>

                                                </Row>
                                            </Col>
                                            <Col xs={24} style={{marginTop:"15px"}}>
                                                <Row gutter="10" justify="left">

                                                </Row>
                                            </Col>
                                            <Col xs={24}  style={{marginTop:"15px"}}>

                                                <Row justify="start"  align="bottom"><Button type="primary" href={`/essay/${item.id}`}>布置作业</Button></Row>

                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )
                        })
                    )
            }
        </Row>
    )
}

export default EssayCard;
