import {Content} from "antd/lib/layout/layout";
import EssayCard from "../components/EssayCard";
import {useEffect, useState} from "react";
import axios from "axios";

const OutCourseEssays = () => {
    const [essays, setEssays] = useState([])

    useEffect(() => {
        axios.get("/api/essay", {
            params: {
                type: 2
            }
        }).then(response => {
            if (response.data.value) {
                setEssays(response.data.value.map((value, index) => {
                    value.key = index
                    return value
                }))
            }
        })
    }, [])

    return (
        <Content>
            <EssayCard essays={essays} />
        </Content>
    )
}

export default OutCourseEssays;
