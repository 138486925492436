import { useParams } from "react-router-dom";
import { Tooltip, Tag, Result, Button, Input, Modal, Alert, Space, List, Col, Row, Card, notification, Image } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom"
import { getUserId } from "../utils";
import { RightCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Homework from "../components/Homework";
import HomeworkInfo from '../components/HomeworkInfo';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import { formatDate } from '../utils';

const { Meta } = Card;
const Homework_msg = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onClose = (e) => {
        console.log(e, 'I was closed.');
    };

    let [data, setData] = useState([]);

    useEffect(() => {
        axios({
            method: "get",
            url: "/api/homeworkMsg",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                studentId: getUserId(),
                status: 0
            }
        }).then(res => {
            let homeworks = res.data;
            let total = homeworks.length;

            // console.log(homeworks)

            if (total > 0) {
                setIsModalOpen(true);
            } else {
                setIsModalOpen(false);
            }

            console.log(total)
            setData(homeworks);
            // return (<Homework_msg />)
        })
            .catch(err => {
                // return (<Homework_msg />)
                console.log(err)
            });
    }, [])

    return (
        <>

            <Modal title={<h2>您有作业未提交！</h2>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" href="myhomework" onClick={handleOk}>
                        前往提交
                    </Button>,
                ]}>
                <Space direction="vertical"
                    style={{
                        width: '100%',
                    }}>
                    {data.map(h => (
                        <div className="small-alert">
                            <Alert

                                style={{}}
                                message={h.name}
                                description={"截止时间：" + h.endTime}
                                type="warning"
                                showIcon
                            />
                        </div>
                    ))}
                </Space>
            </Modal>
        </>
    );
};


const TeacherWelcome = () => {
    const [classId, setClassId] = useState(-1)
    const [homework, setHomework] = useState([])

    const [api, contextHolder] = notification.useNotification();

    const dateFormatter = new Intl.DateTimeFormat("default", {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
    })

    useEffect(() => {
        axios.get("/api/homework", {
            params: {
                classId: classId === -1 ? undefined : classId
            }
        }).then(response => {
            const sortedHomework = response.data.value.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
            const slicedHomework = sortedHomework.slice(0, 3);
            setHomework(slicedHomework);
            // setHomework(response.data.value.reverse())
            console.log("response data", slicedHomework)
        }).catch(() => {
            api.error({
                message: "获取作业数据时出现错误，请稍后再试。"
            })
        })
    }, [classId])

    return (
        <>
            {contextHolder}
            <div className="w-100" style={{ flex: 1, minHeight: 0, padding: "1em", display: "flex", flexDirection: "column" }}>
                <div className="w-100" style={{ display: "flex", flex: 1, minHeight: 0, alignItems: "center", flexDirection: "column", overflowY: "auto" }}>
                    {
                        homework.map(h => (
                            // Date.now() < Date.parse(h.endTime) ?
                            <div className="w-100" style={{ padding: "0 1.25em", margin: "0.5em 0" }} key={h.id}>
                                <Homework data={h} dateFomatter={dateFormatter} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

const StudentWelcome = () => {
    let localItem = sessionStorage.getItem('userInfo')
    const userInfo = localItem ? JSON.parse(localItem) : null
    let [data, setData] = useState([]);

    window.timer = null;
    useEffect(() => {
        clearTimeout(window.timer);
        window.timer = setTimeout(() => {
            showTable();
        }, 0)
    }, [])

    const showTable = () => {

        axios({
            method: "get",
            url: "/api/homework/studentHomework",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                id: userInfo.id
            }
        }).then(res => {
            let homeworks = res.data;
            let total = homeworks.length;
            homeworks.forEach((r, i) => {
                r.key = i;
            });
            homeworks = homeworks.filter(i => {
                return i.status != 2 & i.status != 1
            })

            console.log(homeworks)

            // homeworks = [homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],]
            setData(homeworks);
        })
            .catch(err => {

            });
    }
    return (
        <Layout
            style={{
                padding: "18px"
            }}>
            <Content>
                {/* <Table columns={columns} dataSource={data} pagination={true} /> */}

                {data.length === 0 ? (
                    <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
                        <img src="/like.png" style={{
                            width: "40px",
                            marginLeft: 15
                        }} />
                        <span style={{ fontSize: "25px", color: "rgba(0, 0, 0, 0.88)", marginLeft: 15 }}><b>你真棒！已经完成了所有作业!</b></span>
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
                        <img src="/alert.png" style={{
                            width: "40px",
                            marginLeft: 15
                        }} />
                        <span style={{ fontSize: "25px", color: "black", marginLeft: 15 }}><b>你有作业未提交!</b></span>
                        <Button type="link" href={'myhomework'} style={{ textAlign: "left", marginLeft: 15, marginTop: 10 }}>
                            <span>查看更多</span>
                            <RightCircleOutlined />
                        </Button>
                    </div>

                )}
                {data.length == 0 && (
                    <Row style={{ padding: "2em" }} justify="start" align="top" gutter={[10, 20]}>
                        <Col xl={4} xs={8}>
                            <Link to="myhomework">
                                <Card
                                    hoverable
                                    style={{ textAlign: "center" }}
                                    cover={<img alt="example" src="/research.png" style={{
                                        width: "50%",
                                        padding: "1em",
                                        margin: "auto"
                                    }} />}
                                >
                                    <Meta title="查看批改结果" />
                                </Card>
                            </Link>

                        </Col>
                    </Row>
                )}
                {data.length > 0 && (
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 3,
                            xl: 3,
                            xxl: 3,
                        }}
                        pagination={{
                            onChange: (page) => {
                                console.log(page);
                            },
                            pageSize: 6,
                            align: 'center'
                        }}
                        dataSource={data}
                        renderItem={(homework) => (
                            <List.Item>
                                <Card
                                    title={homework.name}
                                    extra={
                                        <Tooltip title="作业完成情况">
                                            <Tag
                                                color={
                                                    homework.status === 0
                                                        ? 'volcano'
                                                        : homework.status === 1
                                                            ? 'geekblue'
                                                            : homework.status === 2
                                                                ? 'green'
                                                                : homework.status === 3
                                                                    ? 'yellow'
                                                                    : 'default'
                                                }
                                            >
                                                {homework.status === 0
                                                    ? '未提交'
                                                    : homework.status === 1
                                                        ? '未复批'
                                                        : homework.status === 2
                                                            ? '已复批'
                                                            : homework.status === 3
                                                                ? '打回'
                                                                : ''}
                                            </Tag>
                                        </Tooltip>
                                    }
                                    actions={[
                                        <Tooltip title="作业详情">
                                            <HomeworkInfo info={homework} />
                                        </Tooltip>,
                                    ]}
                                >
                                    <p>发布时间：{formatDate(new Date(homework.startTime))}</p>
                                    <p>截止时间：{formatDate(new Date(homework.endTime))}</p>
                                    <p>作业描述：{homework.description}</p>
                                    {
                                        homework.note && <p>备注：{homework.note}</p>
                                    }
                                </Card>
                            </List.Item>
                        )}
                    />
                )}
            </Content>
        </Layout>
    )
}

const WelcomeInfo = () => {
    let localItem = sessionStorage.getItem('userInfo');
    const userInfo = localItem ? JSON.parse(localItem) : null
    const navigate = useNavigate()
    if (userInfo) {
        console.log('userInfo', userInfo)

        if (userInfo.role.search('teacher') !== -1) {
            return (
                <>
                    {/* <Result
                        icon={<SmileOutlined />}
                        title="欢迎使用小花狮智能辅导系统"
                    /> */}
                    {/* <Homework_msg /> */}
                    {/* <Row style={{padding: "2em"}} justify="start" align="top" gutter={[10, 20]}>
                        <Col span={4}>
                            <Button type="primary" style={{width:'200px', height:'50px'}}><Link to="/app/essay">布置作业</Link></Button>
                        </Col>

                        <Col span={4}>
                            <Button type="primary" style={{width:'200px', height:'50px'}}><Link to="/app/classManagement">班级管理</Link></Button>
                        </Col>
                    </Row> */}

                    <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
                        <img src="/elion.png" style={{
                            width: "40px",
                            marginLeft: 15
                        }} />
                        <span style={{ fontSize: "25px", color: "black", marginLeft: 10, marginTop: 10 }}><b>欢迎使用小花狮智能辅导系统!</b></span>
                    </div>

                    <Row style={{ padding: "2em" }} justify="start" align="top" gutter={[10, 20]}>
                        <Col xl={4} xs={8}>
                            <Link to="essay">
                                <Card
                                    hoverable
                                    style={{ textAlign: "center" }}
                                    cover={<img alt="example" src="/notebook.png" style={{
                                        width: "50%",
                                        padding: "1em",
                                        margin: "auto"
                                    }} />}
                                >
                                    <Meta title="布置作业" />
                                </Card>
                            </Link>
                        </Col>
                        <Col xl={4} xs={8}>
                            <Link to="classManagement">
                                <Card
                                    hoverable
                                    style={{ textAlign: "center" }}
                                    cover={<img alt="example" src="/chalkboard.png" style={{
                                        width: "50%",
                                        padding: "1em",
                                        margin: "auto"
                                    }} />}
                                >
                                    <Meta title="班级管理" />
                                </Card>
                            </Link>
                        </Col>
                        <Col xl={4} xs={8}>
                            <Link to="homework">
                                <Card
                                    hoverable
                                    style={{ textAlign: "center" }}
                                    cover={<img alt="example" src="/exam.png" style={{
                                        width: "50%",
                                        padding: "1em",
                                        margin: "auto"
                                    }} />}
                                >
                                    <Meta title="批改作业" />
                                </Card>
                            </Link>

                        </Col>
                    </Row>

                    {/* <span style={{color:'red',font-size:'20px',}}>最新作业列表</span> */}
                    <span style={{ fontSize: "25px", color: "black", marginLeft: 30, marginTop: 20 }}><b>最新作业列表</b></span>
                    {/* <Button type="link" icon={<RightCircleTwoTone />} onClick={() => navigate("/app/homework")} style={{ textAlign: "left" , marginLeft:15, marginTop:10}}>查看更多</Button> */}
                    <Button type="link" onClick={() => navigate("homework")} style={{ textAlign: "left", marginLeft: 15, marginTop: 10 }}>
                        <span>查看更多</span>
                        <RightCircleOutlined />
                    </Button>
                    <TeacherWelcome />

                </>
            )
        }

        if (userInfo.role.search('student') !== -1) {
            return (
                <>
                    <StudentWelcome />
                </>
            )
        }

    }

}
export default WelcomeInfo;
