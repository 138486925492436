import Homework from "../components/Homework";
import service from '../api/service';
import React, {useEffect, useState} from "react";
import {Button, notification, Select, Space} from "antd";
import {FileAddOutlined} from "@ant-design/icons";
import {formatClassName} from "../utils";
import {useNavigate} from "react-router-dom";

import "../Lion.css"

const HomeworkManagementHeader = ({ classId, onClassIdChanged }) => {
    const [classOptions, setClassOptions] = useState([])

    const [api, contextHolder] = notification.useNotification();

    const navigate = useNavigate()

    useEffect(() => {
        service.get("/api/class").then(response => {
            setClassOptions(response.data.map(cls => {
                return {
                    value: cls.id,
                    label: formatClassName(cls)
                }
            }).concat({
                value: -1,
                label: "全部班级"
            }))
        }).catch(() => {
            api.error({
                message: "获取班级数据时出现错误，请稍后再试。"
            })
        })
    }, [])

    return (
        <>
            {contextHolder}
            <div className="w-100" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div style={{marginLeft: "1em"}}>
                    <Space>
                        <span style={{color: "grey"}}>班级选择</span>
                        <Select
                            value={classId}
                            style={{width: "8em"}}
                            onChange={onClassIdChanged}
                            options={classOptions} />
                    </Space>
                </div>
                <Button type="link" icon={<FileAddOutlined />} onClick={() => navigate("/essay")}>布置作业</Button>
            </div>
        </>
    )
}

const HomeworkManagement = () => {
    const [classId, setClassId] = useState(-1)
    const [homework, setHomework] = useState([])

    const [api, contextHolder] = notification.useNotification();

    const dateFormatter = new Intl.DateTimeFormat("default", {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
    })

    useEffect(() => {
        service.get("/api/homework", {
            params: {
                classId: classId === -1 ? undefined : classId
            }
        }).then(response => {
            setHomework(response.data.value)
        }).catch(() => {
            api.error({
                message: "获取作业数据时出现错误，请稍后再试。"
            })
        })
    }, [classId])

    return (
        <>
            {contextHolder}
            <div className="w-100" style={{flex: 1, minHeight: 0, padding: "1em", display: "flex", flexDirection: "column"}}>
                <HomeworkManagementHeader classId={classId} onClassIdChanged={value => setClassId(value)} />
                <div className="w-100" style={{display: "flex", flex: 1, minHeight: 0, alignItems: "center", flexDirection: "column", overflowY: "auto"}}>
                    {
                        homework.map(h => (
                            <div className="w-100" style={{padding: "0 1.25em", margin: "0.5em 0"}} key={h.id}>
                                <Homework data={h} dateFomatter={dateFormatter}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default HomeworkManagement;
