import React from 'react';
import { Space, Table, Tag, message, Input, Button, Popconfirm } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import service from '../api/service';
import { useState, useEffect, useRef } from 'react';
import ChangeUserInfo from '../components/ChangeUserInfo';

export default () => {

    let [data, setData] = useState([]);
    let [total, setTotal] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
            ...getColumnSearchProps('name'),
        },
        {
            title: '用户ID',
            dataIndex: 'id',
            key: 'id',
            ...getColumnSearchProps('id'),
        },
        {
            title: "权限",
            key: "role",
            dataIndex: "role",
            render: (role) => {
                return role.split(",").map((r) => {
                    let color = r === "teacher" ? "geekblue" : r === "admin" ? "volcano" : "green";
                    return (
                        <Tag color={color} key={r}>
                            {r.toUpperCase()}
                        </Tag>
                    );
                });
            },
        },

        {
            title: '操作',
            key: 'action',
            render: (text) => (
                <Space size="middle">
                    {/* <Button type="primary" onClick={show.bind(this, text)}>
                        hello
                    </Button> */}
                    <ChangeUserInfo userInfo={text} />
                    <Popconfirm
                        title="确认删除该用户吗？"
                        description="Are you sure to delete?"
                        onConfirm={confirm.bind(this, text.id)}
                        onCancel={null}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a href="#">Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const confirm = (id) => {
        service({
            method: "delete",
            url: "/api/userManagement/deleteUser",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                id: id
            }
        }

        )
            .then(res => {
                message.success(res.data);
            })
            .catch(err => {
                message.error(err.response.data);
            })

    };


    window.timer = null;
    useEffect(() => {
        clearTimeout(window.timer);
        window.timer = setTimeout(() => {
            showTable(1, 10);
        }, 0)
    }, [])

    const showTable = (page_num, page_size) => {
        service({
            method: "get",
            url: "/api/userManagement/users",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                page_size,
                page_num
            }
        }

        )
            .then(res => {
                let users = res.data;
                let total = users.length;
                users.forEach((r, i) => {
                    r.key = i;
                });
                console.log(users);
                setData(users);
                setTotal(total);
            })
            .catch(err => {
                message.error(err.response.data);
            });
    }
    return <Table columns={columns} dataSource={data} pagination={true} />

};
