import { Carousel } from 'antd';

const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const ShowImages = (prop) => {
  console.log(prop.images);
  return (
    <Carousel>
      {
        prop.images.map((image)=>{
            return <div>
                <img src={"/api/submission/image?image="+image} width={"100%"}></img>
            </div>
        })
      }
    </Carousel>
  );
};
export default ShowImages;