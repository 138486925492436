import React, { useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  Button, Checkbox, Form, Input, message,
  Col,
  Row,
  Select,
  Upload, Typography, Spin
} from 'antd';

import { EditorState, ContentState, convertToRaw, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from "draftjs-to-html";
// html 转换为 draft
import htmlToDraft from 'html-to-draftjs'
import "draft-js/dist/Draft.css";
import "./TextRecognition.css";

import service from '../api/service';

import mockData from './mockData';

const { TextArea } = Input;

const { Dragger } = Upload;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 0,
    },
    sm: {
      span: 0,
    },
  },
  wrapperCol: {
    xs: {
      span: 22,
    },
    sm: {
      span: 22,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};


//转意符换成普通字符
function convertIdeogramToNormalCharacter(val) {
  const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
  return val.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) { return arrEntities[t]; });
}

// 获取富文本的纯文字内容
const getPlainText = (richCont) => {
  // const str = richCont;
  let value = richCont;
  console.log(value)  
  if (richCont) {
    // 方法一：
    value = value.replace(/\s*/g, "");  //去掉空格
    value = value.replace(/<br>/gi, "\n"); //把<br>标签替换为换行符
    value = value.replace(/<[^>]+>/g, ""); //去掉所有的html标记
    // value = value.replace(/↵/g, "");     //去掉所有的↵符号
    //value = value.replace(/[\r\n]/g, "") //去掉回车换行
    value = value.replace(/&nbsp;/g, "") //去掉空格
    value = value.replace(/\n+/g, "\n") //去掉空格
    value = convertIdeogramToNormalCharacter(value);
    return value;

    // 方法二：
    // value = value.replace(/(\n)/g, "");
    // value = value.replace(/(\t)/g, "");
    // value = value.replace(/(\r)/g, "");
    // value = value.replace(/<\/?[^>]*>/g, "");
    // value = value.replace(/\s*/g, "");
    // value = convertIdeogramToNormalCharacter(value);
    // return value;
  } else {
    return null;
  }
}

const TextRecognition = () => {
  const [form] = Form.useForm();
  const [imgList, setImgList] = useState([]);
  const [fileList, setFileList] = useState([]); //文件列表
  const [imageUrls, setImageUrls] = useState([]);
  const [activeImg, setActiveImg] = useState(0); //选中图片下标
  const [maxCount, setMaxCount] = useState(10); //最大上传数量
  const [loading, setLoading] = useState(false); // 定义 loading 状态
  const editorRef = useRef()

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  let localItem = sessionStorage.getItem('userInfo')
  const userInfo = localItem ? JSON.parse(localItem) : null
  let { homeworkId } = useParams();
  const handleEditorChange = (state) => {
    // console.log('state', state)
    // toHtml(state)
    setEditorState(state);
    const content = toHtml(state) + ''
    console.log(content)
    if (content != '<p></p>\n') {
      setTimeout(() => {
        // form.setFieldsValue({ content:  content })
        form.setFieldsValue({ content: getPlainText(content) })
      }, 10);
    }
  };


  const toHtml = value => {
    // console.log(convertToRaw(value.getCurrentContent()))
    let templateContent = draftToHtml(convertToRaw(value.getCurrentContent()))
    console.log(templateContent)
    templateContent = templateContent.replace(/<\/p>/g, '</span><br>')
      .replace(/<p.*?>/gi, '<span style="white-space: pre-wrap;">');
    return templateContent;
  }

  // 转换为 Draft
  const toDraft = value => {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }


  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // console.log('ssss', file)
    if (!isJpgOrPng) {
      message.error('请上传 JPG/PNG 图片文件!');
      return false;
    }
    if (imageUrls.length === maxCount) {
      message.info(`最多可上传${maxCount}图片,请删除后重新上传！`)
      return false;
    }
    // return isJpgOrPng;
    return false; //阻止提交，用手动提交
  };

  const getBase64Promise = (img) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result));
      reader.readAsDataURL(img);
    })
  };

  const draggerProps = {
    name: 'images',
    maxCount: maxCount,
    action: 'https://down.akios.cn/api/fs/form',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': '*/*'
    },
    beforeUpload: beforeUpload,
    fileList: imgList,
    multiple: true,
    onChange(info) {
      const { status, originFileObj } = info.file;
      if (status !== 'uploading') {
        // console.log(1111, info.file, info.fileList);

        const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
        if (!isJpgOrPng) return;

        setImgList(info.fileList)
        setFileList(info.fileList.map(v => v.originFileObj))

        // imageUrls
        Promise.all(info.fileList.map(v => getBase64Promise(v.originFileObj))).then(res => {
          setImageUrls(res)
        })
      }
      if (status === 'done') {
        message.success(`${info.file.name} 图片上传成功`);
      } else if (status === 'error') {
        message.error(`${info.file.name} 图片上传失败`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const Nav = useNavigate();
  const onFinish = (values) => {
    // console.log(values.content)
    // return
    setLoading(true); // 发送请求前设置loading为true
    console.log('Received values of form: ', values);

    const formData = new FormData();

    // fileList.map(v => {
    //   formData.append('images', v);
    // })

    console.log(values)

    formData.append('content', values.content);
    service({
      method: "post",
      url: "/api/submission/submit",
      data: formData,
      params: {
        studentId: userInfo.id,
        homeworkId: homeworkId,
        path: "test"
      },
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(res => {
      setLoading(false); // 请求完成后设置loading为false
      message.success(res.data);
      Nav(`/myhomework/${homeworkId}/${userInfo.id}`)
    }).catch(err => {
      setLoading(false); // 请求完成后设置loading为false
      message.error(err.response.data);
    })
  };


  // 清空图片列表
  const clearImgs = (index) => {
    if (Object.prototype.toString.call(index) === '[object Number]') {
      if (index === 0 && imageUrls.length === 1) {
        setActiveImg(0)
      } else if (index <= activeImg) {
        if (index !== 0 || (index === 0 && index !== activeImg)) {
          setActiveImg(activeImg - 1)
        }
      }
      imageUrls.splice(index, 1)
      setImageUrls([...imageUrls])
      fileList.splice(index, 1)
      setFileList([...fileList])
      imgList.splice(index, 1)
      setImgList([...imgList])
    } else {
      setImageUrls([])
      setFileList([])
      setImgList([])
      setActiveImg(0)
    }
  }

  // 处理上传并识别
  const handleUpload = () => {
    const formData = new FormData();

    fileList.map(v => {
      formData.append('images', v);
    })

    service({
      method: "post",
      url: "/api/textfile/upload",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then(res => {
        // 模拟数据

        if (res.data.data && res.data.data.paragraphs) {
          let value = ''
          res.data.data.images.map(item => {
            let str = ''

            item.lines.map(v => {
              if (Number(v.score) < 0.8) {
                str += `<span style="color: #FF69B4;">${v.text}</span>`
                return;
              }
              if (Number(v.score) < 0.9) {
                str += `<span style="color: #dbb322;">${v.text}</span>`
                return;
              }
              str += `<span style="color: #000;">${v.text}</span>`
            })

            value += `<p style="text-indent: 2em; color: #000;">${str}<span style="#000"> </span></p>`
          })

          setEditorState(toDraft(value))

          editorRef.current.focusEditor()
          setTimeout(() => {
            form.setFieldsValue({ content: getPlainText(value) })
          }, 10);
        }
      })
      .catch(err => {
        // console.err('err', err)
      })
  }

  return (<div className='text-recognition' style={{ margin: "auto", width: "80%", height: '100%', overflow: 'auto', marginLeft: "auto", marginTop: "20px" }}>
    {loading && <Spin size="large" tip="正在批改中，可能需要30s左右，请耐心等待" />} {/* 根据 loading 状态显示 Spin */}
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        content: "",
      }}
      scrollToFirstError

      style={{
        height: '100%',
      }}
    >

      <Row style={{
        height: '100%',
      }}>

        <Col flex="500px">
          <Form.Item
            name="image"
            style={{ width: 500 }}
            labelCol={{ span: 0, offset: 0 }}
            wrapperCol={{ span: 24, offset: 0 }}
          // label="图片"
          // rules={[
          //   {
          //     required: true,
          //     message: '请上传图片!',
          //     whitespace: true,
          //   },
          // ]}
          >
            <Dragger showUploadList={false} {...draggerProps}>
              {imageUrls[activeImg] ? <img src={imageUrls[activeImg]} alt="avatar" style={{ width: '100%', height: '500px' }} /> : (<><p style={{ marginTop: 20 }} className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
                <p className="ant-upload-text">单击或拖动文件到此区域上载</p>
                <p style={{ marginBottom: 20 }} className="ant-upload-hint">
                  {/* 每次上传单张图片，再次上传将会替换 */}
                  最多上传{maxCount}张图片
                </p></>)}
            </Dragger>

            <div className="small-imgs">
              <Row style={{ width: '100%' }} gutter={8}>
                {
                  imageUrls.length > 0 && imageUrls.map((item, index) => {
                    return (<Col span={5} key={index.toString()}>
                      <img onClick={() => {
                        setActiveImg(index)
                      }} className={activeImg === index ? 'active' : ''} src={item} key={index.toString()}></img>
                      <DeleteOutlined onClick={() => {
                        clearImgs(index)
                      }} className='delete-icon' />
                    </Col>);
                  })
                }
              </Row>
            </div>
            <Button
              type="primary"
              onClick={clearImgs}
              disabled={imgList.length === 0}
              // loading={uploading}
              style={{ marginTop: 20, height: 50, }}
            >
              清空图片列表
            </Button>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={imgList.length === 0}
              // loading={uploading}
              style={{ marginTop: 20, marginLeft: 10, height: 50, }}
            >
              上传图片并识别
            </Button>
          </Form.Item>
        </Col>
        <Col flex="1">
          <Form.Item
            labelCol={{ span: 0, offset: 0 }}
            wrapperCol={{ span: 22, offset: 2 }}
            name="content"
            // label="文字"
            rules={[
              {
                required: true,
                message: '请填写文字!',
                whitespace: true,
              },
            ]}
          >
            <Typography.Text type="secondary" style={{ fontSize: '12px', marginBottom: '10px' }}>
              <span style={{ color: '#dbb322' }}>黄色字体</span>: 个别文字可能有误<br />
              <span style={{ color: '#FF69B4' }}>红色字体</span>: 整段文字可能有误
            </Typography.Text>
            <Editor
              placeholder={'请输入文字，或上传图片自动识别文字，上传后可能需要手动修正内容'}
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{ options: [] }}
              stripPastedStyles={true}
              ref={editorRef}
            />
            {/* <TextArea placeholder='请输入文字，或点击右方上传图片自动识别文字' style={{ height: 500, resize: 'none' }} /> */}
          </Form.Item>
          <Form.Item
            labelCol={{ span: 0, offset: 0 }}
            wrapperCol={{ span: 22, offset: 2 }}
          >
            <Button style={{
              width: '100%',
              height: 50,
              marginTop: 10
            }} type="primary" htmlType="submit">
              提交作业
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form></div>)

}
export default TextRecognition;
