import {Input, Select, Space, Tag, Tooltip} from "antd";
import {AudioOutlined, StarOutlined, SaveOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

const {TextArea} = Input;
const { CheckableTag } = Tag;

const OverallEvaluation = ({ title, evaluation, fieldName }) => {
    const firstCharLowerCase = str => `${str[0].toLowerCase()}${str.substring(1)}`
    const scoreFieldName = firstCharLowerCase(`${fieldName}Score`)
    const descriptionFieldName = firstCharLowerCase(`${fieldName}Description`)
    const tagsFieldName = firstCharLowerCase(`${fieldName}Tags`)

    const [isEditing, setIsEditing] = useState(false)
    const [score, setScore] = useState(evaluation[scoreFieldName])
    const [description, setDescription] = useState(evaluation[descriptionFieldName])
    const [selectedTags, setSelectedTags] = useState(evaluation[tagsFieldName]);

    const userInfoJson = sessionStorage.getItem("userInfo")
    const isTeacher = JSON.parse(userInfoJson).role.split(",").includes("teacher")

    const tagsData = (fieldName) => {
        switch(fieldName) {
            case 'wordSentence':
                return ['词句多样', '语言优美', '善用修辞', '选词得当'];
            case 'expression':
                return ['逻辑严谨', '结构清晰', '层次分明', '构思巧妙'];
            case 'fluency':
                return ['文笔通顺', '文章流畅', '文笔如流', '行文连贯'];
            case 'suggestion':
                return ['继续努力', '独具匠心', '巧妙布局', '一目了然'];
            default:
                return [];
        }
    }

    const tagSelectionChangeHandler = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
        evaluation[tagsFieldName] = nextSelectedTags
    };

    useEffect(() => {
        setScore(evaluation[scoreFieldName])
        setDescription(evaluation[descriptionFieldName])
        setSelectedTags(evaluation[tagsFieldName])
    }, [evaluation])

    const options = [{
        value: 2,
        label: "A"
    }, {
        value: 1,
        label: "B"
    }, {
        value: 0,
        label: "C"
    }]

    const selectScoreHandle = value => {
        setScore(value)
        evaluation[scoreFieldName] = value
    }
    const inputChangeHandler = event => {
        setDescription(event.target.value)
        evaluation[descriptionFieldName] = event.target.value
    }

    return (
        <div onClick={() => !isEditing && setIsEditing(true)} className="card-container card-border" style={{marginBottom: "0.5em", backgroundColor: "white", padding: "0.5em 1em"}}>
            <div style={{height: "2.5em"}}>
                <Space align="center">
                    <b>{title}</b>
                    {
                        isTeacher ? (
                            fieldName !== "suggestion" && <Select size="small" style={{width: "3.3em"}} value={score} options={options} onChange={selectScoreHandle} />
                        ) : (
                            <b>{options.filter(option => option.value === score)[0]?.label}</b>
                        )
                    }
                </Space>
            </div>

            <Space style={{marginLeft: 8}} size={[0, 8]} wrap>
                {tagsData(fieldName).map((tag) => (
                <CheckableTag
                    key={tag}
                    checked={selectedTags.includes(tag)}
                    onChange={(checked) => isTeacher && tagSelectionChangeHandler(tag, checked)}
                    style={{border: '1px solid #d9d9d9', borderRadius: '4px'}}
                >
                     <Tooltip title="Starred">
                        <StarOutlined style={{marginRight: '8px'}} />
                    </Tooltip>
                    {tag}
                </CheckableTag>
                ))}
            </Space>

            <div style={{position: 'relative', width: "100%", marginTop: "0.5em"}}>
                {
                    isTeacher ? (
                        <>
                            <TextArea rows={4} value={description} onChange={inputChangeHandler} allowClear />
                            <AudioOutlined style={{position: 'absolute', bottom: '10px', right: '10px', zIndex: 1}} />
                        </>
                    ) : (
                        <span>{description}</span>
                    )
                }

            </div>

        </div>
    )
}

export default OverallEvaluation;
