import {Badge, Image} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';

import '../App.css';
import "../Lion.css"


let selectedSpanKey = ""


const Sentence = ({ sentence, evaluation, selectedKey, onClickSelect, onOpenAddEvaluationDialog, preKey }) => {
    const [words, setWords] = useState([])

    const generateWordHighlight = useCallback(() => {
        const temp = []
        let start = 0
        evaluation.forEach(word => {
            if (word.span[0] > start) {
                temp.push({
                    start: start,
                    end: word.span[0]
                })
            }
            temp.push({
                start: word.span[0],
                end: word.span[1],
                isGoodWord: true,
                type: word.type
            })
            start = word.span[1]
        })
        if (start < sentence.length) {
            temp.push({
                start: start,
                end: sentence.length
            })
        }
        return temp
    }, [evaluation])

    useEffect(() => {
        setWords(generateWordHighlight)
    }, [generateWordHighlight])

    const spanClickHandler = (e, key) => {
        e.stopPropagation()
        onClickSelect(key)
    }
    const spanMouseDownHandler = key => {
        selectedSpanKey = key
    }
    const spanMouseUpHandler = (e, key) => {
        if (!onOpenAddEvaluationDialog) {
            return;
        }
        if (key !== selectedSpanKey) {
            return
        }

        const regex = /.*\.notGood\./g
        const offset = parseInt(key.replace(regex, ""))
        onOpenAddEvaluationDialog(e, offset)
    }

    let lastGoodEnd = 0
    return (
        words.map(w => {
            if (w.isGoodWord) {
                const key = `${preKey}.${w.start}`
                lastGoodEnd = w.end
                return (
                    <span className={w.type["level1"] === "作文亮点" ? "metaphor" : "arrange"}
                          key={key} onClick={e => spanClickHandler(e, key)}>
                        {sentence.substring(w.start, w.end)}
                    </span>
                )
            } else {
                const key = `${preKey}.notGood.${lastGoodEnd}`
                return (
                    <span onMouseDown={_ => spanMouseDownHandler(key)}
                          onMouseUp={e => spanMouseUpHandler(e, key)}
                          key={key}>{sentence.substring(w.start, w.end)}</span>
                )
            }
        })
    )
}

const Paragraph = ({ pi, sentences, evaluation, selectedKey, onClickSelect, onOpenAddEvaluationDialog, preKey }) => {
    return (
        <p className="para">
            <Badge count={"第" + (pi + 1) + "段"} className={"para-index"} />
            {
                sentences.map((s, si) => {
                    const key = `${preKey}.${si}`

                    if (evaluation[si].isGoodSent) {
                        return (
                            <span className={evaluation[si].type["level1"] === "作文亮点" ? "metaphor" : "arrange"} key={key}
                                  onClick={_ => onClickSelect(key)}>
                                <Sentence sentence={s} selectedKey={selectedKey} evaluation={evaluation[si].wordEvaluations} onClickSelect={onClickSelect} preKey={key} />
                            </span>
                        )
                    } else {
                        return (
                            <span key={key}>
                                <Sentence sentence={s} evaluation={evaluation[si].wordEvaluations} onClickSelect={onClickSelect}
                                          selectedKey={selectedKey} onOpenAddEvaluationDialog={onOpenAddEvaluationDialog(si)} preKey={key} />
                            </span>
                        )
                    }
                })
            }
        </p>
    )
}

const Composition = ({ essay, generatedImages, evaluation, selectedKey, onClickSelect, onOpenAddEvaluationDialog }) => {
    return (
        <div style={{lineHeight: "200%"}}>
            {
                essay.map((p, pi) => (
                    <Paragraph pi={pi} sentences={p} evaluation={evaluation[pi]} key={pi} preKey={pi} selectedKey={selectedKey}
                               onClickSelect={onClickSelect} onOpenAddEvaluationDialog={onOpenAddEvaluationDialog(pi)} />
                ))
            }
            {
                generatedImages.map((b64Image, index) => (
                    <Image key={index} style={{padding: "1em"}} width="25%" src={`data:image/png;base64,${b64Image}`} />
                ))
            }
        </div>
    )
}
export default Composition;
