import { React, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, DatePicker, Form, Input, Descriptions, Badge, Modal, Tag } from "antd";
import { formatDate } from '../utils';




export default (props) => {
    const { info } = props
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        <Button type="primary" >
            hello
        </Button >
        // setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const Status = (status) => {
        if (status === 0) {
            return (
                <Tag color={'volcano'} key={status}>
                    {'未提交'}
                </Tag>
            )
        };

        if (status === 1) {
            return (
                <Tag color={'geekblue'} key={status}>
                    {'未复批'}
                </Tag>
            )
        };
        if (status === 2) {
            return (
                <Tag color={'green'} key={status}>
                    {'已复批'}
                </Tag>
            )
        };
        if (status === 3) {
            return (
                <Tag color={'yellow'} key={status}>
                    {'打回'}
                </Tag>
            )
        };
    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                查看详情
            </Button>
            <Modal title="作业详情" open={isModalOpen} onCancel={handleCancel} width={'100%'} footer={null}>
                <Descriptions bordered>
                    <Descriptions.Item label="作业名">{info.name}</Descriptions.Item>
                    <Descriptions.Item label="作业id" span={2}>{info.id}</Descriptions.Item>
                    <Descriptions.Item label="发布时间">{formatDate(new Date(info.startTime))}</Descriptions.Item>
                    <Descriptions.Item label="截止时间" span={2}>{formatDate(new Date(info.endTime))}</Descriptions.Item>
                    <Descriptions.Item label="完成情况" span={3}>
                        {Status(info.status)}
                    </Descriptions.Item>
                    <Descriptions.Item label="作业要求" span={3}>{info.description}</Descriptions.Item>
                    {
                        info.note && <Descriptions.Item label="备注" span={3}>{info.note}</Descriptions.Item>
                    }
                </Descriptions>
                <Button type="primary" style={{ marginTop: '3%', marginLeft: '50%' }} href={'/textRecognition/' + info.id}>
                    上传作业
                </Button >
            </Modal>


        </>
    )
}


