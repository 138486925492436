import {Popconfirm, Row, Space} from "antd";
import {CloseOutlined, DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import WordSentenceSelection from "./WordSentenceSelection";
import {useEffect, useState} from "react";

const SentenceEvaluation = ({ isActive, evaluation, sentence, sentenceIndex, onSetSelection, onDeleteSentenceEvaluation }) => {
    const [selectionValue, setSelectionValue] = useState(evaluation.type)

    useEffect(() => setSelectionValue(evaluation.type), [evaluation])

    const userInfoJson = sessionStorage.getItem("userInfo")
    const isTeacher = JSON.parse(userInfoJson).role.split(",").includes("teacher")

    const resetClickHandler = event => {
        event.stopPropagation()
        setSelectionValue(evaluation.type)
        onSetSelection("")
    }
    const saveClickHandler = () => {
        evaluation.type = selectionValue
        onSetSelection("")
    }

    return (
        <div onClick={() => !isActive && isTeacher && onSetSelection(sentenceIndex)} className="card-container card-border" style={{marginBottom: "0.5em", backgroundColor: "white", padding: "0.5em 1em"}}>
            <Row justify="space-between" align="top">
                {
                    isActive && isTeacher ? (
                        <>
                            <div style={{marginLeft: "1.5em"}}>
                                <WordSentenceSelection selectedValue={Array.from(Object.values(selectionValue))} onEvaluationChanged={setSelectionValue} />
                            </div>
                            <Space>
                                <CloseOutlined onClick={resetClickHandler} />
                                <SaveOutlined onClick={saveClickHandler} />
                            </Space>
                        </>
                    ) : (
                        <>
                            <Row align="middle">
                                <div style={{width: "0.5em", height: "0.5em", borderRadius: "50%", backgroundColor: evaluation.type["level1"] === "作文亮点" ? "blue" : "red"}} />
                                <div style={{color: "grey", marginLeft: "1em"}}>{`${evaluation.type["level2"]}${evaluation.type["level3"] ? ` > ${evaluation.type["level3"]}` : ""}`}</div>
                            </Row>
                            {
                                isTeacher && <Popconfirm
                                    title="删除点评"
                                    description="确认删除点评？"
                                    onConfirm={onDeleteSentenceEvaluation}
                                    onPopupClick={e => e.stopPropagation()}
                                    okText="确认"
                                    cancelText="取消">
                                    <DeleteOutlined onClick={resetClickHandler}  />
                                </Popconfirm>
                            }
                        </>
                    )
                }
            </Row>
            <div style={{marginLeft: "1.5em"}}>{sentence}</div>
        </div>
    )
}

export default SentenceEvaluation;
