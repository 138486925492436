import {Link} from "react-router-dom";
import {Result} from "antd";
import React from "react";

const NotFound = () => (
    <div style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Result status="404" title="页面走丢了" subTitle="您所请求的页面不存在。" extra={<Link to="/">返回首页</Link>} />
    </div>
)

export default NotFound;
