import React from 'react';
import {Table, message, Button, Popconfirm, Layout, Typography, Row, Col, notification} from 'antd';
import Highlighter from 'react-highlight-words';
import service from '../api/service';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { DeleteTwoTone } from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
export default () => {
    let { classId } = useParams();
    const classes = JSON.parse(sessionStorage.getItem('classes'));
    const thisclass = classes.find(c => c.id === parseInt(classId));
    let [data, setData] = useState([]);
    let [total, setTotal] = useState(0);
    const { Header, Content } = Layout;
    const { Title, Text } = Typography;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()
    const getColumnSearchProps = (dataIndex) => ({
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const deleteStudent = (classId, studentId) => {
        return service.delete("/api/class", {
            params: {
                classId: classId,
                studentId: studentId
            }
        }).then(response => {
            if (response.data) {
                api.success({
                    message: "成功删除学生",
                    duration: 2
                })
                showTable()
            }
        })
    }


    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
            ...getColumnSearchProps('name'),
        },
        {
            title: '用户ID',
            dataIndex: 'id',
            key: 'id',
            ...getColumnSearchProps('id'),
        },
        {
            title: '电话号码',
            dataIndex: 'tel',
            key: 'tel',
            ...getColumnSearchProps('tel'),
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     render: ()
        // }
        {
            title: '操作',
            key: 'operation',
            render: (_, record) => (
                <Popconfirm
                    title="删除学生"
                    description="确认将该学生从班级中删除吗？"
                    onConfirm={_ => deleteStudent(classId, record.id)}
                    okText="确认"
                    cancelText="取消">
                    <Button type="text" >
                        <DeleteTwoTone style={{ fontSize: '20px' }} twoToneColor="#FF4D4F" />
                    </Button>
                </Popconfirm>
            ),
        },
    ];

    useEffect(() => {
        showTable();
    }, [])

    const showTable = () => {
        service({
            method: "get",
            url: "/api/class/getUserByClassID",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                id: classId
            }
        }

        )
            .then(res => {
                let users = res.data;
                let total = users.length;
                users.forEach((r, i) => {
                    r.key = i;
                });
                console.log(users);
                setData(users);
                setTotal(total);
            })
            .catch(err => {
                message.error(err.response.data);
            });
    }

    return (
        <Layout>
            {contextHolder}
            {/* <Header style={headerStyle}> */}
                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Title level={3} style={{ marginBottom: 0, fontSize: '24px', color: '#7A8C8E' }}>
                        班级: {thisclass.enrollmentYear}级{thisclass.num}班
                    </Title>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '16px' }}>
                        <Text style={{ fontSize: '16px', color: '#5B6970' }}>
                            班级邀请码：{thisclass.code}
                        </Text>
                        <Text style={{ fontSize: '16px', color: '#A5AD98' }}>
                            学生总数：{data.length}
                        </Text>
                    </div>
                </div> */}
                <Row gutter={0}>
                    <Col span={8}>
                        <Title level={3} style={{ marginBottom: 0, fontSize: '24px', color: '#7A8C8E' }}>
                            班级: {thisclass.enrollmentYear}级{thisclass.num}班
                        </Title>
                    </Col>
                    <Col span={8}>
                        <Text style={{ fontSize: '16px', color: '#5B6970' }}>
                            班级邀请码：{thisclass.code}
                        </Text>
                    </Col>
                    <Col span={8}>
                        <Text style={{ fontSize: '16px', color: '#A5AD98' }}>
                            学生总数：{data.length}
                        </Text>
                    </Col>
                </Row>
            {/* </Header> */}
            <Content style={{ marginTop: '16px' }}>
                < Table columns={columns} dataSource={data} pagination={true} />
            </Content>
        </Layout>
    )



};
