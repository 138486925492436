import {Space, Statistic} from "antd";

const HomeworkStats = ({ data }) => {
    return (
        <Space size="large">
            <Statistic
                title="总人数"
                value={data.total}
                suffix="人"
            />
            <Statistic
                title="未提交"
                value={data.unsubmitted}
                valueStyle={{color: "red"}}
                suffix="人"
            />
            <Statistic
                title="待复批"
                value={data.unreviewed}
                valueStyle={{color: "orange"}}
                suffix="人"
            />
            <Statistic
                title="已复批"
                value={data.reviewed}
                valueStyle={{color: "green"}}
                suffix="人"
            />
        </Space>
    )
}

export default HomeworkStats;
