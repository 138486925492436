import React from 'react';
import { Space, Table, Tag, message, Input, Button, Popconfirm, Modal, List, Card, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import service from '../api/service';
import { useState, useEffect, useRef } from 'react';
import ChangeUserInfo from '../components/ChangeUserInfo';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import HomeworkInfo from './HomeworkInfo';

import { formatDate } from '../utils';


export default () => {
    let localItem = sessionStorage.getItem('userInfo')
    const userInfo = localItem ? JSON.parse(localItem) : null
    let [data, setData] = useState([]);
    const searchInput = useRef(null);




    const columns = [
        {
            title: '作业名称',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => <p>  {name} </p>,
            // ...getColumnSearchProps('name'),
        },
        {
            title: '发布时间',
            key: 'startTime',
            dataIndex: 'startTime',
            // render: (identity) => {
            //     var color = identity === 'teacher' ? 'geekblue' : 'green';

            //     if (identity === 'admin') {
            //         color = 'volcano';
            //     };

            //     return (
            //         <Tag color={color} key={identity}>
            //             {identity.toUpperCase()}
            //         </Tag>
            //     )
            // }
        },
        {
            title: '截止时间',
            dataIndex: 'endTime',
            key: 'endTime',
        },

        {
            title: '作业描述',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '完成情况',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {

                if (status === 0) {
                    return (
                        <Tag color={'volcano'} key={status}>
                            {'未提交'}
                        </Tag>
                    )
                };

                if (status === 1) {
                    return (
                        <Tag color={'geekblue'} key={status}>
                            {'未复批'}
                        </Tag>
                    )
                };
                if (status === 2) {
                    return (
                        <Tag color={'green'} key={status}>
                            {'已复批'}
                        </Tag>
                    )
                };
                if (status === 3) {
                    return (
                        <Tag color={'yellow'} key={status}>
                            {'打回'}
                        </Tag>
                    )
                };
            }
        },



        {
            title: '操作',
            key: 'action',
            render: (text) => (
                <Space size="middle">
                    <HomeworkInfo info={text} />
                </Space>
            ),
        },
    ];


    window.timer = null;
    useEffect(() => {
        clearTimeout(window.timer);
        window.timer = setTimeout(() => {
            showTable();
        }, 0)
    }, [])



    const showTable = () => {

        service({
            method: "get",
            url: "/api/homework/studentHomework",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                id: userInfo.id
            }
        }).then(res => {
            let homeworks = res.data;
            let total = homeworks.length;
            homeworks.forEach((r, i) => {
                r.key = i;
            });
            homeworks = homeworks.filter(i => {
                // return i.status != 2
                return i.status === 0
            })

            console.log(homeworks)
            // 对homeworks数组按截止时间进行排序
            homeworks.sort((a, b) => new Date(a.endTime) - new Date(b.endTime));

            // homeworks = [homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],]
            setData(homeworks);
        })
            .catch(err => {

            });
    }

    const getStatusIcon = (status) => {

        if (status === 0) {
            return (
                <Tag color={'volcano'} key={status}>
                    {'未提交'}
                </Tag>
            )
        };

        if (status === 1) {
            return (
                <Tag color={'geekblue'} key={status}>
                    {'未复批'}
                </Tag>
            )
        };
        if (status === 2) {
            return (
                <Tag color={'green'} key={status}>
                    {'已复批'}
                </Tag>
            )
        };
        if (status === 3) {
            return (
                <Tag color={'yellow'} key={status}>
                    {'打回'}
                </Tag>
            )
        };
    }

    return (
        <Layout
            style={{
                padding: "18px"
            }}>
            <Content>
                {/* <Table columns={columns} dataSource={data} pagination={true} /> */}
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 3,
                        xxl: 3,
                    }}
                    pagination={{
                        onChange: (page) => {
                            console.log(page);
                        },
                        pageSize: 6,
                        align: 'center'
                    }}
                    dataSource={data}
                    renderItem={(homework) => (
                        <List.Item>
                            <Card
                                title={homework.name}
                                extra={
                                    <Tooltip title="作业完成情况">
                                        <Tag
                                            color={
                                                homework.status === 0
                                                    ? 'volcano'
                                                    : homework.status === 1
                                                        ? 'geekblue'
                                                        : homework.status === 2
                                                            ? 'green'
                                                            : homework.status === 3
                                                                ? 'yellow'
                                                                : 'default'
                                            }
                                        >
                                            {homework.status === 0
                                                ? '未提交'
                                                : homework.status === 1
                                                    ? '未复批'
                                                    : homework.status === 2
                                                        ? '已复批'
                                                        : homework.status === 3
                                                            ? '打回'
                                                            : ''}
                                        </Tag>
                                    </Tooltip>
                                }
                                actions={[
                                    <Tooltip title="作业详情">
                                        <HomeworkInfo info={homework} />
                                    </Tooltip>,
                                ]}
                                bodyStyle={{ height: '120px', overflow: 'hidden' }} // 设置卡片内容的固定高度并隐藏溢出部分
                            >
                                <p>发布时间：{formatDate(new Date(homework.startTime))}</p>
                                <p>截止时间：{formatDate(new Date(homework.endTime))}</p>
                                <Tooltip title={`${homework.description}\n${homework.note ?? ""}`}> {/* 使用Tooltip组件来显示作业描述文本，并在悬停时显示完整内容 */}
                                    <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', marginBottom: 0 }}>{homework.description}</p> {/* 使用CSS属性来控制溢出文本的省略号效果 */}
                                </Tooltip>
                                {/* <p>作业描述：{homework.description}</p> */}
                            </Card>
                        </List.Item>
                    )}
                />
            </Content>
        </Layout>
    )

};
