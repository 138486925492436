import {Line, Pie, G2, Radar, WordCloud, Bar} from "@ant-design/charts";
import {Button, Col, Divider, Grid, Image, Row, Select, Space, Statistic, Table} from "antd";
import {FileTextOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import html2canvas from "html2canvas";


const { useBreakpoint } = Grid;


const HomeworkStats = ({ data }) => {
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center"}}>
            <Statistic
                title="作业总次数"
                value={data.count}
                suffix="次"
            />
            <Statistic
                title="平均字数"
                value={data.averageWordNum?.toFixed(2)}
                suffix="字"
            />
            <Statistic
                title="范文数量"
                value={data.modelEssayNum}
                suffix="篇"
            />
        </div>
    )
}

const WritingScoreAnalysis = ({ className, homeworkName }) => {
    const G = G2.getEngine('canvas');

    const screens = useBreakpoint();

    const tableColumns = [{
        title: "作文等级",
        dataIndex: "level",
        key: "level"
    }, {
        title: "逻辑关系类别个数",
        dataIndex: "logicKindNum",
        key: "logicKindName"
    }, {
        title: "逻辑关系类型",
        dataIndex: "logicType",
        key: "logicType"
    }]

    const {homeworkId} = useParams()
    const [userInfo, setUserInfo] = useState({})
    const [classHomeworkData, setClassHomeworkData] = useState({})
    const [scoreLineData, setScoreLineData] = useState([])
    const [wordCloudData, setWordCloudData] = useState([])
    const [radarData, setRadarData] = useState([])
    const [pieData, setPieData] = useState([])
    const [barData, setBarData] = useState([])
    const [interval, setInterval] = useState(1)

    const intervalOptions = [{
        label: "近三个月",
        value: 1
    }, {
        label: "近半年",
        value: 2
    }, {
        label: "近一年",
        value: 3
    }]

    const scoreLineConfig = {
        data: scoreLineData,
        xField: "score",
        yField: "value",
        seriesField: 'type',
        xAxis: {
            title: {
                text: "成绩等级"
            }
        },
        yAxis: {
            title: {
                text: "人数"
            }
        }
    }

    const wordCloudConfig = {
        data: wordCloudData,
        wordField: 'name',
        weightField: 'value',
        colorField: 'name',
        wordStyle: {
            fontFamily: 'Verdana',
            fontSize: [8, 32]
        },
        // 设置交互类型
        interactions: [{
            type: 'element-active'
        }]
    };

    const radarConfig = {
        data: radarData,
        xField: "label",
        yField: "value",
        seriesField: "type",
        meta: {
            value: {
                min: 0
            }
        },
        point: {
            size: 2
        }
    }

    const pieConfig = {
        data: pieData,
        angleField: "value",
        colorField: "type",
        radius: 0.5,
        legend: false,
        label: {
            type: 'spider',
            labelHeight: 40,
            formatter: (data, mappingData) => {
                const group = new G.Group({});
                group.addShape({
                    type: 'circle',
                    attrs: {
                        x: 0,
                        y: 0,
                        width: 40,
                        height: 50,
                        r: 5,
                        fill: mappingData.color,
                    },
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 10,
                        y: 8,
                        text: `${data.type}`,
                        fill: mappingData.color,
                    },
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 0,
                        y: 25,
                        text: `${data.value}个 ${(data.percent * 100).toFixed(2)}%`,
                        fill: 'rgba(0, 0, 0, 0.65)',
                        fontWeight: 700,
                    },
                });
                return group;
            }
        },
        interactions: [{
            type: 'element-selected',
        }, {
            type: 'element-active',
        }],
    }

    const barConfig = {
        data: barData,
        isGroup: true,
        xField: 'value',
        yField: 'label',

        seriesField: 'type',
        marginRatio: 0,
        label: {
            position: 'middle',
            layout: [{
                type: 'interval-adjust-position'
            }, {
                type: 'interval-hide-overlap'
            }, {
                type: 'adjust-color'
            }],
        },
    };

    useEffect(() => {
        const info = JSON.parse(sessionStorage.getItem("userInfo"))
        info.intervalTime = Math.ceil((Date.now() - info.createTime) / (24 * 60 * 60 * 1000))
        setUserInfo(info)
    }, [])

    useEffect(() => {
        axios.get("/api/homework/analysis", {
            params: {
                id: homeworkId,
                interval: interval
            }
        }).then(response => {
            if (response) {
                setClassHomeworkData(response.data.value.classHomeworkData)
                setScoreLineData(response.data.value.writingScores)
                setWordCloudData(response.data.value.hotWords)
                setRadarData(response.data.value.writingSkillAnalysis)
                setPieData(response.data.value.sickSentences)
                setBarData(response.data.value.rhetoricUsage)
            }
        })
    }, [homeworkId, interval])

    const analysisRef = useRef()

    const exportPNG = () => {
        html2canvas(analysisRef.current, {
            backgroundColor: "rgb(245, 245, 245)"
        }).then(canvas => {
            canvas.toBlob(blob => {
                const file = new File([blob], "Test.png")
                const exportUrl = URL.createObjectURL(file)
                const link = document.createElement("a")
                link.setAttribute('download', "Test.png")
                link.href = exportUrl
                link.click()
                link.remove()
            }, 'image/png')
        })
    }

    return (
        <Row ref={analysisRef} style={{height: "100%", position: "relative", flex: 1}} gutter={8}>
            <Space style={{position: "absolute", top: "0.2em", right: "1.5em", zIndex: 10}}>
                <Select
                    size="small"
                    style={{width: "8em"}}
                    options={intervalOptions}
                    defaultValue={1}
                    onChange={setInterval} />
                <Button data-html2canvas-ignore size="small" onClick={exportPNG}>导出</Button>
            </Space>

            <Col style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}} xs={24} lg={12} xl={8}>
                <div style={{width: "100%", padding: "1em"}}>
                    <div style={{width: "100%", backgroundColor: "white", padding: "1em"}}>
                        <Col span={24}>
                            <Space direction="vertical" size="small" style={{display: "flex"}}>
                                <b style={{fontSize: "x-large"}}>{userInfo.name}</b>
                                <div style={{color: "grey"}}>小花狮已经陪伴您{userInfo.intervalTime}天啦~</div>
                            </Space>
                        </Col>
                        <Col xs={24}>
                            <Space direction="vertical" size="small" style={{display: "flex"}}>
                                <b style={{fontSize: "large"}}>班级信息</b>
                                <div>{className}</div>
                            </Space>
                        </Col>
                        <Col xs={24}>
                            <Space direction="vertical" size="small" style={{display: "flex"}}>
                                <b style={{fontSize: "large"}}>班级教师</b>
                                <div>{userInfo.name}</div>
                            </Space>
                        </Col>
                    </div>
                </div>
                <div style={{width: "100%", padding: "1em"}}>
                    <div style={{width: "100%", backgroundColor: "lightgrey", padding: "1em", borderRadius: "2em"}}>
                        <div style={{width: "100%", fontSize: "x-large", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <FileTextOutlined />
                            <span style={{marginLeft: "0.5em"}}>班级作业</span>
                        </div>
                        <HomeworkStats data={classHomeworkData} />
                    </div>
                </div>
                <div style={{width: "100%", minHeight: "38em", flex: 1, padding: "1em"}}>
                    <div style={{width: "100%", height: "100%", backgroundColor: "white", border: "solid", borderWidth: "1px", display: "flex", flexDirection: "column"}}>
                        <div style={{paddingLeft: "3em", height: "2.5em", lineHeight: "2.5em", fontSize: "large"}}>
                            <b>热门词汇</b>
                        </div>
                        <Divider style={{height: "1em", margin: 0}} />
                        <WordCloud style={{width: "100%", flex: 1}} {...wordCloudConfig} />
                    </div>
                </div>
            </Col>
            <Col style={{height: "100%", display: "flex", flexDirection: "column"}} xs={24} lg={12} xl={16}>
                <Row justify="center" align="middle">
                    <b style={{fontSize: "medium"}}>题目：{homeworkName}</b>
                </Row>
                <Row style={{width: "100%", minHeight: "20em", height: "30%", padding: "1em"}}>
                    <div style={{width: "100%", height: "100%", backgroundColor: "white", padding: "0.5em", display: "flex", flexDirection: "column"}}>
                        <b style={{fontSize: "small"}}>写作成绩曲线</b>
                        <Line style={{width: "100%", flex: 1}} {...scoreLineConfig} />
                    </div>
                </Row>
                <Row style={{width: "100%", flex: 1, padding: "1em", minHeight: "45em"}} justify="center" align="middle">
                    <Col style={{width: "100%", height: "100%", paddingRight: "1em"}} xs={24} xl={12}>
                        <div style={{width: "100%", height: "100%", backgroundColor: "white"}}>
                            <div style={{paddingLeft: "1em", paddingTop: "1em"}}>
                                <b>写作能力分析</b>
                            </div>
                            <Divider />
                            <Radar style={{width: "100%"}} {...radarConfig} />
                        </div>
                    </Col>
                    <Col style={{width: "100%", height: "100%", paddingLeft: "1em", display: "flex", flexDirection: "column", justifyContent: "space-between"}} xs={24} xl={12}>
                        <div style={{width: "100%", height: "45%", padding: "0.5em", display: "flex", flexDirection: "column", backgroundColor: "white"}}>
                            <b>病句统计图</b>
                            {
                                pieData.length !== 0 ? (
                                    <Pie style={{width: "100%", flex: 1}} {...pieConfig} />
                                ) : (
                                    <div style={{width: "100%", flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <img src={`${process.env.PUBLIC_URL}/EmptyAnalysis.svg`} />
                                        <span>当前作业行文流畅，表达清晰，继续保持好的写作习惯！</span>
                                    </div>
                                )
                            }
                        </div>
                        {/*<div style={{width: "100%", height: "30%", padding: "0.5em", display: "flex", flexDirection: "column", backgroundColor: "white"}}>*/}
                        {/*    <b>逻辑关系统计表</b>*/}
                        {/*    <Table*/}
                        {/*        style={{width: "100%", flex: 1}}*/}
                        {/*        size="small"*/}
                        {/*        columns={tableColumns} />*/}
                        {/*</div>*/}
                        <div style={{width: "100%", height: "45%", padding: "0.5em", display: "flex", flexDirection: "column", backgroundColor: "white"}}>
                            <b>修辞使用统计图</b>
                            {
                                barData.length !== 0 ? (
                                    <Bar style={{width: "100%", flex: 1}} {...barConfig} />
                                ) : (
                                    <div style={{width: "100%", flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <img src={`${process.env.PUBLIC_URL}/EmptyAnalysis.svg`} />
                                        <span>当前作业中修辞使用较少，可以继续培养相关的修辞技能以丰富写作表达！</span>
                                    </div>
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default props => <WritingScoreAnalysis {...props} params={useParams()} />;
