import React, { useState } from 'react';
import {
    Button, Checkbox, Form, Input, Layout, message, Result, AutoComplete,
    Cascader,
    Col,
    InputNumber,
    Row,
    Select,
    DatePicker
} from 'antd';


import { EditTwoTone } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import service from '../api/service';
import { useNavigate } from 'react-router-dom';
const { Header, Footer, Sider } = Layout;
const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


const ClassForm = () => {


    const [form] = Form.useForm();
    const Nav = useNavigate();
    const year = new Date().getFullYear();
    const onFinish = (fieldsValue) => {
        const values = {
            ...fieldsValue,
        };
        let localItem = sessionStorage.getItem('userInfo')
        const userInfo = localItem ? JSON.parse(localItem) : null
        service.post("/api/class/createClass", values, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        }

        )
            .then(res => {
                message.success('创建成功');
                setTimeout(() => {
                    window.location.reload();
                }, 2000); // 延迟 2 秒钟，然后刷新页面
            })
            .catch(err => {
                message.error(err.response.data.message);
            })

    };
    return (<Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{

        }}
        scrollToFirstError
    >



        <Form.Item
            name="num"
            label="班级编号"
            rules={[        //校验规则
                {
                    required: true,
                    message: '请选择班级编号!',
                },
            ]}
        >
            <InputNumber min={1} max={20} />
        </Form.Item>





        <Form.Item
            name="enrollmentYear"
            label="入学年份"
            rules={[
                {
                    required: true,
                    message: '请选择入学年份!',
                },
            ]}
        >

            <InputNumber min={year - 10} max={year + 10} />
        </Form.Item>


        <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
                创建
            </Button>
        </Form.Item>
    </Form>)

}
export default ClassForm;
