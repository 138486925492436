import { Tabs } from 'antd';
import ClassHomework from '../components/ClassHomework';
import History from '../components/History';
import InCourseHomework from '../components/InCourseHomework';
import ModelEssay from '../components/ModelEssay';

const MyHomework = () => (
    <Tabs style={{marginLeft:'15px', marginRight:'15px'}}
        items={[{
            label: "班级作业",
            key: "essay",
            children: <ClassHomework />
        },
        {
            label: "教材题库",
            key: "inCourse",
            children: <InCourseHomework />
        },
        {
            label: "历史记录",
            key: "history",
            children: <History />
        },
        {
            label: "范文天地",
            key: "modelessay",
            children: <ModelEssay />
        }]}
    />
)

export default MyHomework;
