import {Link, useMatches} from "react-router-dom";
import {Breadcrumb} from "antd";

const Breadcrumbs = () => {
    const matches = useMatches()
    const crumbs = matches
        .filter(match => Boolean(match.handle?.crumb))
        .map(match => match.handle.crumb(match.data, match.params))

    const itemRender = (item, params, items, paths) => {
        const last = items.indexOf(item) === items.length - 1;
        return last ? <span>{item.title}</span> : <Link to={paths.join('/')}>{item.title}</Link>;
    }

    return (
        <Breadcrumb
            className='desktop'
            style={{ marginLeft: '15px', marginTop: "10px" }}
            separator=">"
            items={crumbs}
            itemRender={itemRender} />
    )
}

export default Breadcrumbs;
