import React from 'react';
import { Button, Checkbox, Form, Input, Layout, message, Result ,Avatar} from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { EditTwoTone } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import service from '../api/service';
import { useNavigate, Navigate } from 'react-router-dom';
const { Header, Footer, Sider } = Layout;
const Login = () => {
    const Nav = useNavigate();
    const onFinish = (values) => {
        console.log('Success:', values);
        // service.get('/api/logsys/login', {
        //     params: {
        //         tel: values.tel,
        //         password: values.password
        //     }
        // }).then(res => {
        //     message.success(res.data);
        //     Nav('/app/home');
        // }).catch(err => {
        //     message.error(err.response.data);
        // })

        // 随便换个接口，模拟登录成功了
        service.get('/api/logsys/login', {
            params: {
                tel: values.tel,
                password: values.password
            }
        }).then(res => {
            // message.success(res.data);
            sessionStorage.setItem('userInfo', JSON.stringify(
                res.data
            ))
            sessionStorage.setItem("user", res.data)
            Nav('/');
        }).catch(err => {
            message.error(err.response.data);
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error("请填写账号密码！")

    };
    let localItem = sessionStorage.getItem('userInfo')
    const userInfo = localItem ? JSON.parse(localItem) : null
    if (userInfo) {
        return <Navigate to='/' replace={true} />
    }
    return (<Layout style={{ height: "100%" }}>
        <Header className="site-layout-sub-header-background" >
            <h1>
                <Avatar src={<img src={"/elion.png"} alt="avatar" />} style={{ marginLeft: "12px" }} />
                <span style={{ marginLeft: "6px" }}>小花狮作文智能辅导系统</span>
            </h1>
        </Header>
        <Content >
            <Result
                icon={<SmileOutlined />}
                title="欢迎使用小花狮智能辅导系统"
            />
            <div style={{ margin: "auto", width: "400px", marginLeft: "auto" }}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4, offset: 0
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="手机号"
                        name="tel"
                        rules={[
                            {
                                required: true,
                                message: '请输入手机号!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 5,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            登录
                        </Button>

                        <Button type="link" style={{ marginLeft: "100px" }} href={'/signup'} >
                            注册
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        </Content>
        <Footer style={{ textAlign: 'center', padding: "5px 10px" }} >
            小花狮 ©2022 Created by CubeNLP
        </Footer>
    </Layout >
    );
};
export default Login;
