import React, { useState, useEffect } from 'react';
import {
    Button, Checkbox, Form, Input, Layout, message, Result, AutoComplete,
    Cascader,
    Col,
    InputNumber,
    Row,
    Select,
    DatePicker
} from 'antd';
import SelfInfo from '../components/SelfInfo';

import { EditTwoTone } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import service from '../api/service';
import { useNavigate } from 'react-router-dom';
const { Header, Footer, Sider } = Layout;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const Info = () => {


    const [form] = Form.useForm();
    const Nav = useNavigate();

    const onFinish = (fieldsValue) => {
        const values = {
            ...fieldsValue,
        };
        console.log('Received values of form: ', values);
        var user = JSON.stringify({
            tel: values.tel,
            password: values.password,
            identity: userInfo.identity,
            name: values.name,
            gender: values.gender,
            id: userInfo.id
        });
        console.log('json: ', user);
        service({
            method: "post",
            url: "/api/userManagement/changeUserInfoByAdmin",
            data: user,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }

        )
            .then(res => {
                message.success(res.data);
            })
            .catch(err => {
                message.error(err.response.data);
            })

    };
    var [userInfo, setInfo] = useState({});
    window.timer = null;
    useEffect(() => {
        clearTimeout(window.timer);
        window.timer = setTimeout(() => {
            getInfo();
        }, 0)
    }, [])
    const getInfo = () => {
        service({
            method: "get",
            url: "/api/userManagement/getSelfInfo",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }

        )
            .then(res => {
                message.success("成功获取");
                setInfo(res.data);

            })
            .catch(err => {
                message.error(err.response.data);
            })
    }
    return (

        <div style={{ margin: "auto", width: "400px", marginLeft: "auto", marginTop: "auto" }}>
            <SelfInfo userInfo={userInfo} />
        </div>
    )
}
export default Info;