import {message} from "antd";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";

const Logout = () => {
    const navigate = useNavigate()

    useEffect(() => {
        axios.get('/api/logsys/logout').then(res => {
            sessionStorage.clear();
            navigate("/login")
        }).catch(err => {
            message.error(err.response.data);
        })
    }, [])
}

export default Logout;
