import axios from 'axios';
import { message } from 'antd';

const service = axios.create({
  baseURL: '/',
});

service.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        // 在这里进行页面跳转
        message.error("登录信息过期，重新登录");
        window.location.href="/logout"
      }
      return Promise.reject(error);
    }
  );

export default service;
