import React from 'react';
import {Col,Row, Select} from "antd";
import {blue} from "@ant-design/colors";

import {useState, useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import EssayCard from "../components/EssayCard";
import axios from "axios";



const InCourseEssays = () => {

    const [textbookVersions, setTextbookVersions] = useState([])
    const [grades, setGrades] = useState([])
    const [essays, setEssays] = useState([])
    const [textbookVersionSelected, setTextbookVersionSelected] = useState(0)
    const [gradeSelected, setGradeSelected] = useState(0)

    useEffect(() => {
        axios.get("/api/grade")
            .then(response => {
                if (response.data.value) {
                    setGrades(response.data.value)
                    setGradeSelected(response.data.value[0].id)
                }
            })
        axios.get("/api/textbook-version")
            .then(response => {
                if (response.data.value) {
                    setTextbookVersions(response.data.value)
                    setTextbookVersionSelected(response.data.value[0].id)
                }
            })
    }, [])

    useEffect(() => {
        axios.get("/api/essay", {
            params: {
                type: 0,
                textbookVersion: textbookVersionSelected,
                grade: gradeSelected
            }
        }).then(response => {
            if (response.data.value) {
                setEssays(response.data.value.map((value, index) => {
                    value.key = index
                    return value
                }))
            }
        })
    }, [textbookVersionSelected, gradeSelected])

    return (
        <div>
            <Row align="middle">
                <Col>
                    <div style={{ width: '100%' }}>
                        <span>教版：</span>
                        {
                            textbookVersions.map((textbookVersion) => (
                                <span
                                    onClick={() => setTextbookVersionSelected(textbookVersion.id)}
                                    style={{
                                        ...(textbookVersion.id === textbookVersionSelected
                                            ? { color: blue.primary, cursor: 'pointer' }
                                            : { cursor: 'pointer' }),
                                        marginLeft: '1em',
                                    }}
                                    key={textbookVersion.id}>
                                    {textbookVersion.name}
                                </span>
                            ))
                        }
                    </div>
                </Col>
                <Col>
                    <div style={{ width: '100%', marginLeft: "20px"}}>
                        <span>年级：</span>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="请选择年级"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.children ?? '').indexOf(input) >= 0
                            }
                            value={gradeSelected}
                            onChange={value => setGradeSelected(value)}>
                            {
                                grades.map((grade) => (
                                    <Select.Option key={grade.id} value={grade.id}>
                                        {grade.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                </Col>
            </Row>


            <Content style={{padding: "1em"}}>
                <EssayCard essays={essays} />
            </Content>
        </div>
    )
}

export default InCourseEssays;
