import React from 'react';
import {
    Space, Table, Tag, message, Input, Button, Modal, Form, Row,
    Select,
    DatePicker, Col
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import service from '../api/service';
import { useState, useEffect, useRef } from 'react';
import { OmitProps } from 'antd/lib/transfer/ListBody';
import { useNavigate } from 'react-router-dom';
import InfoForm from './InfoForm';
const { Option } = Select;
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: '请输入生日!'
        },
    ],
};
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};

const ChangeUserInfo = (props) => {

    const { userInfo } = props;
    let [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };
    const handleCancel = () => {
        setOpen(false);
        window.location.reload();
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                修改用户信息
            </Button>
            <Modal
                open={open}
                title="修改用户信息"
                onCancel={handleCancel}
                footer={null}
            >
                <InfoForm userInfo={userInfo} />
            </Modal>
        </>
    );
};
export default ChangeUserInfo;