import {Tag} from "antd";

const SubmissionStatusTag = ({ status }) => {
    switch (status) {
        case 0:
            return <Tag color="error">未提交</Tag>
        case 1:
            return <Tag color="warning">待复批</Tag>
        case 2:
            return <Tag color="success">已复批</Tag>
        case 3:
            return <Tag color="error">打回重写</Tag>
    }
}

export default SubmissionStatusTag;
