import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, DatePicker, Form, Input, notification, Select} from "antd";
import service from '../api/service';
import {formatClassName} from "../utils";
import moment from 'moment';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

moment.locale('zh-cn');


const { TextArea } = Input;

const AssignHomework = () => {
    const {essayId} = useParams()

    const [loading, setLoading] = useState(false)
    const [classSelectorValue, setClassSelectorValue] = useState([])
    const [classSelectorLoading, setClassSelectorLoading] = useState(false)
    const [classOptions, setClassOptions] = useState([])

    const navigate = useNavigate();

    const [form] = Form.useForm()
    const [api, contextHolder] = notification.useNotification();

    const publishHomework = value => {
        service.post("/api/homework", value.classIds.map(classId => {
            return Object.assign({}, value, { classId: classId })
        })).then((res) => {
            if (res.data.value) {
                setLoading(true)
                api.success({
                    message: "成功发布作业，页面即将跳转。",
                    duration: 2,
                    onClose: () => {
                        setLoading(false)
                        navigate(`/homework`)
                    }
                })
            } else {
                throw res;
            }
        }).catch(() => {
            api.error({
                message: "发布时出现错误，请稍后再试。"
            })
            setLoading(false)
        })
    }

    useEffect(() => {
        if (essayId < 0) {
            return
        }
        service.get("/api/essay/detail", {
            params: {
                id: essayId
            }
        }).then(response => {
            form.setFieldValue("name", response.data.value.name)
            form.setFieldValue("description", response.data.value.description)
        }).catch(() => {
            api.error({
                message: "获取作业数据时出现错误，请刷新后重试。"
            })
        })
    }, [])

    useEffect(() => {
        setClassSelectorLoading(true)
        service.get("/api/class").then(response => {
            setClassOptions(response.data.map(cls => {
                return {
                    value: cls.id,
                    label: formatClassName(cls)
                }
            }))
        }).catch(() => {
            api.error({
                message: "获取班级数据时出现错误，请刷新后重试。"
            })
        }).finally(() => setClassSelectorLoading(false))
    }, [])


    const disabledDate = (current) => {
        // 禁止选择今天之前的日期
        return current && current < moment().endOf('day');
      };

      const disabledTime = (selectedDate) => {
        const hours = moment().hours();
        const minutes = moment().minutes();
        const disabledHours = [];
        const disabledMinutes = [];

        // 禁止选择当前小时之前的时间
        if (selectedDate && selectedDate.isSame(moment(), 'day')) {
          for (let i = 0; i < hours; i++) {
            disabledHours.push(i);
          }

          // 禁止选择当前分钟之前的时间
          for (let i = 0; i < minutes; i++) {
            disabledMinutes.push(i);
          }
        }

        return {
          disabledHours: () => disabledHours,
          disabledMinutes: () => disabledMinutes,
        };
      };


    return (
        <>
            {contextHolder}
            <div className="w-100 h-100" style={{padding: "1em"}}>
                <Form
                    form={form}
                    style={{width: "60%", marginInline: "auto"}}
                    labelCol={{sm: 8, md: 6, lg: 4, xl: 3, xxl: 2}}
                    onFinish={publishHomework}>
                    <Form.Item label="作业名称：" name="name" required>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="班级：" name="classIds" rules={[{ required: true, message: "请选择班级！" }]}>
                        <Select
                            mode="multiple"
                            value={classSelectorValue}
                            loading={classSelectorLoading}
                            options={classOptions}
                            onChange={value => setClassSelectorValue(value)}
                        />
                    </Form.Item>
                    <Form.Item label="截止时间：" name="endTime" rules={[{ required: true, message: "请选择截止时间！" }]}>
                        <DatePicker locale={locale}
                            showTime={{
                                format: 'HH:mm',
                                disabledMinutes: () => [],
                                disabledSeconds: () => [],
                            }}
                            format="YYYY-MM-DD HH:mm"
                            disabledDate={disabledDate}
                            disabledTime={(value) => disabledTime(value, 'start')}
                        />
                    </Form.Item>
                    <Form.Item label="写作要求：" name="description" rules={[{ required: true, message: "请输入写作要求！" }]}>
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item label="备注：" name="note">
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item wrapperCol={{sm: {offset: 12}, md: {offset: 10}}}>
                        <Button loading={loading} style={{marginInline: "auto"}} htmlType="submit">发 布 作 业</Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

// eslint-disable-next-line
export default props => <AssignHomework {...props} params={useParams()} />;
