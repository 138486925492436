import React, {useEffect} from 'react';
import {
  Button, Form, Input, message,
  Select,
  Space
} from 'antd';

import service from '../api/service';
import {useNavigate} from 'react-router-dom';

const {Option} = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};


const SelfInfo = (props) => {
  const navigate = useNavigate()

  // let localItem = sessionStorage.getItem('userInfo')
  // const userInfo = localItem ? JSON.parse(localItem) : null
  // console.log(userInfo);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(props.userInfo)
    form.setFieldValue("confirm", props.userInfo.password)
  }, [props.userInfo])

  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
    };
    console.log('Received values of form: ', values);
    var user = {
      tel: values.tel,
      password: values.password,
      name: values.name,
      gender: values.gender,
      id: props.userInfo.id,
      role: props.userInfo.role
    };
    console.log('json: ', user);
    service({
        method: "post",
        url: "/api/userManagement/changeSelfUserInfo",
        data: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    ).then(res => {
      message.success(res.data);
      const origin = JSON.parse(sessionStorage.getItem('userInfo'))
      const newUserInfo = {
        ...origin,
        ...user
      }
      sessionStorage.setItem("userInfo", JSON.stringify(newUserInfo))
    }).catch(err => {
      message.error(err.response.data);
    })

  };

  return (<Form
    {...formItemLayout}
    form={form}
    name="register"
    onFinish={onFinish}
    // initialValues={{
    //     tel: userInfo.tel,
    //     password: userInfo.password,
    //     confirm: userInfo.password,
    //     name: userInfo.name,
    //     // gender: (userInfo.gender === 1) ? "男" : "女",
    //     gender: userInfo.gender
    // }}
    scrollToFirstError
  >
    <Form.Item
      name="tel"
      label="手机号码"
      rules={[
        {
          required: true,
          message: '请输入手机号!',
        },
      ]}
    >
      <Input
        // addonBefore={prefixSelector}
        style={{
          width: '100%',
        }}
      />
    </Form.Item>

    <Form.Item
      name="password"
      label="密码"
      tooltip="密码长度至少6位"
      rules={[
        {
          required: true,
          message: '请输入密码!',
        },
        () => ({
          validator(_, value) {
            if (value.length >= 6) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('密码需要至少6位!'));
          },
        }),
      ]}
      hasFeedback
    >
      <Input.Password/>
    </Form.Item>

    <Form.Item
      name="confirm"
      label="确认密码"
      dependencies={['password']}
      hasFeedback
      rules={[
        {
          required: true,
          message: 'Please confirm your password!',
        },
        ({getFieldValue}) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('两次输入的密码不一致!'));
          },
        }),
      ]}
    >
      <Input.Password/>
    </Form.Item>

    <Form.Item
      name="name"
      label="真实姓名"
      rules={[
        {
          required: true,
          message: '请填写你的姓名!',
          whitespace: true,
        },
      ]}
    >
      <Input/>
    </Form.Item>


    <Form.Item
      name="gender"
      label="性别"
      rules={[
        {
          required: true,
          message: '请选择性别!',
        },
      ]}
    >
      <Select placeholder="请选择性别">
        <Option value={1}>男</Option>
        <Option value={0}>女</Option>
      </Select>
    </Form.Item>


    <Form.Item {...tailFormItemLayout}>
      <Space>
        <Button type="primary" htmlType="submit">
          修改
        </Button>
        <span className='mobile'>
                <Button onClick={() => navigate("/logout")} danger>退出登录</Button>
            </span>
      </Space>
    </Form.Item>
  </Form>)

}
export default SelfInfo;
