import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Composition from "../components/Composition";
import {Button, Checkbox, Col, Image, notification, Popconfirm, Row, Select, Space, Tabs, Typography, Modal} from "antd";
import ParagraphEvaluation from "../components/ParagraphEvaluation";
import {useCallback, useEffect, useMemo, useState} from "react";
import service from '../api/service';
import SentenceEvaluation from "../components/SentenceEvaluation";
import {CloseOutlined, HeartOutlined} from "@ant-design/icons";
import {formatClassName} from "../utils";
import OverallEvaluation from "../components/OverallEvaluation";
import SubmissionStatusTag from "../components/SubmissionStatusTag";
import WordSentenceEvaluationSelection from "../components/WordSentenceSelection";
import ShowImages from "../components/ShowImages"

const { Title } = Typography;


const SubmissionDetailHeader = () => {
    const {homeworkId, studentId} = useParams()

    const [submissionStatus, setSubmissionStatus] = useState({})

    const [api, contextHolder] = notification.useNotification()

    useEffect(() => {
        service.get("/api/submission", {
            params: {
                homeworkId: homeworkId,
                studentId: studentId
            }
        }).then(response => {
            // if (!response.data.value.total) {
            //     throw response
            // }
            setSubmissionStatus(response.data.value)
        }).catch(_ => {
            api.error({
                message: "网络异常，请稍后再试。"
            })
        })
    }, [homeworkId])

    return (
        <>
            {contextHolder}
            {
                submissionStatus.value && submissionStatus.value.total? 
                    <Row className="w-100" style={{padding: "0.5em"}} gutter={[8, 8]}>
                        <Col xs={12} md={8} xl={5}>
                            <div style={{color: "grey"}}>作业名称</div>
                            <b>{submissionStatus.homeworkName}</b>
                        </Col>
                        <Col xs={12} md={8} xl={5}>
                            <div style={{color: "grey"}}>班级</div>
                            <div style={{color: "grey"}}>{formatClassName(submissionStatus)}</div>
                        </Col>
                        <Col xs={12} md={8} xl={5}>
                            <div style={{color: "grey"}}>批复进度</div>
                            <div>
                                <span style={{color: "grey"}}>{`${submissionStatus.reviewed}/${submissionStatus.total}`}</span>
                                <span style={{color: "grey"}}>（复批数/总人数）</span>
                            </div>
                        </Col>
                        <Col xs={12} md={12} xl={5}>
                            <div style={{color: "grey"}}>学生姓名</div>
                            <b>{submissionStatus.studentName}</b>
                        </Col>
                        <Col xs={12} md={12} xl={4}>
                            <div style={{color: "grey"}}>状态</div>
                            <SubmissionStatusTag status={submissionStatus.status} />
                        </Col>
                    </Row> : <></>
                
            }
        </>
    )
}

const AddEvaluationDialog = ({ onSubmitEvaluation, onCloseDialog }) => {
    const [selectionValue, setSelectionValue] = useState({})

    const submitEvaluationHandler = type => {
        onSubmitEvaluation(type, selectionValue)
        onCloseDialog()
    }

    const closeDialogHandler = () => {
        setSelectionValue({})
        onCloseDialog()
    }

    return (
        <>
            <div style={{display: "flex", justifyContent: "end"}}>
                <CloseOutlined style={{cursor: "pointer"}} onClick={closeDialogHandler} />
            </div>
            <div style={{margin: "0.5em"}}>
                <WordSentenceEvaluationSelection selectedValue={Array.from(Object.values(selectionValue))} onEvaluationChanged={setSelectionValue} />
            </div>
            <div style={{display: "flex", justifyContent: "end", alignItems: "center", margin: "0.5em"}}>
                <Space>
                    <Button onClick={() => submitEvaluationHandler(0)}>以词点评保存</Button>
                    <Button onClick={() => submitEvaluationHandler(1)}>以句点评保存</Button>
                </Space>
            </div>
        </>
    )
}

const SubmissionEssayDisplay = ({ essay, sentenceEvaluation, isModelEssay, options, selectValue, selectedKey, onClickSelect, onSubmissionSelect, onOpenAddEvaluationDialog }) => {
    const [essayLength, setEssayLength] = useState(0)
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    useEffect(() => {
        setEssayLength(essay.currText.reduce((partialSum, paragraph) => {
            return partialSum + paragraph.reduce((ps, sentence) => ps + sentence.length, 0)
        }, 0))
    }, [essay.currText])

    const openAddEvaluationDialogHandler = paragraphIndex => {
        return sentenceIndex => {
            return (mouseUpEvent, offset) => {
                onOpenAddEvaluationDialog({
                    x: mouseUpEvent.pageX,
                    y: mouseUpEvent.pageY,
                    paragraphIndex: paragraphIndex,
                    sentenceIndex: sentenceIndex,
                    offset: offset
                })
            }
        }
    }

    return (
        <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
            <Image style={{position: "absolute", top: "1em", right: "1em", minWidth: "3em", maxHeight: "5em", width: "7%", objectFit: "contain"}}
                   src={`${process.env.PUBLIC_URL}/ModelEssay.png`}  alt="范文" hidden={!isModelEssay} />
            <div style={{flex: "auto", padding: "1em", overflow: "auto"}}>
                <Title style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <span>{essay.title}</span>
                </Title>
                <Composition
                    essay={essay.currText}
                    generatedImages={essay.generatedImages}
                    evaluation={sentenceEvaluation}
                    selectedKey={selectedKey}
                    onClickSelect={onClickSelect}
                    onOpenAddEvaluationDialog={openAddEvaluationDialogHandler} />
            </div>
            <Row gutter={[8, 8]} align="middle" justify="space-around">
                <Col xs={24} xl={12} xxl={9}>
                    <span style={{color: "grey"}}>版本&提交时间：</span>
                    <Select
                        style={{width: "13em"}}
                        value={selectValue}
                        options={options}
                        onChange={value => onSubmissionSelect(value)} />
                </Col>
                <Col xs={8} xl={4} xxl={5}>
                    {`${essayLength}字`}
                </Col>
                <Col xs={8} xl={4} xxl={5}>
                    {
                        essay.images&&essay.images.length?<Button type="primary" onClick={()=>setIsImageModalOpen(true)}>
                        查看图片
                      </Button>:<></>
                    }
                </Col>
                <Col xs={8} xl={4} xxl={5}>
                    <HeartOutlined />
                </Col>
            </Row>
            <Modal title="查看作业图片" open={isImageModalOpen} onOk={()=>setIsImageModalOpen(false)} onCancel={()=>setIsImageModalOpen(false)}
                footer={[
                <Button key="back" onClick={()=>setIsImageModalOpen(false)}>
                    关闭
                </Button>,
                ]}>
                {essay.images?<ShowImages images={essay.images}></ShowImages>: "暂无图片"}
            </Modal>
        </div>
    )
}

const SentenceEvaluationTab = ({ essayText, evaluations, clickSelectionValue, onDeleteSentenceEvaluation, onSetSelection }) => {

    return (
        <div className="w-100" style={{width: "100%", overflow: "auto"}}>
            {
                evaluations.map((paragraph, pi) => {
                    return paragraph.map((sentence, si) => {
                        const wordEvaluations = sentence.wordEvaluations.map((word, wi) => {
                            const key = `${pi}.${si}.${word.span[0]}`

                            return (
                                <SentenceEvaluation
                                    isActive={key === clickSelectionValue}
                                    sentenceIndex={key}
                                    key={key}
                                    evaluation={word}
                                    sentence={essayText[pi][si].substring(...word.span)}
                                    onDeleteSentenceEvaluation={() => onDeleteSentenceEvaluation(pi, si, wi)}
                                    onSetSelection={onSetSelection} />
                            )
                        })
                        if (sentence.isGoodSent) {
                            const key = `${pi}.${si}`

                            return (
                                <div key={key}>
                                    <SentenceEvaluation
                                        isActive={key === clickSelectionValue}
                                        sentenceIndex={key}
                                        evaluation={sentence}
                                        sentence={essayText[pi][si]}
                                        onDeleteSentenceEvaluation={() => onDeleteSentenceEvaluation(pi, si)}
                                        onSetSelection={onSetSelection} />
                                    {wordEvaluations}
                                </div>
                            )
                        } else {
                            return wordEvaluations
                        }
                    })
                })
            }
        </div>
    )
}

const ParagraphEvaluationTab = ({ evaluations, paragraphCount }) => {
    return (
        <div className="w-100">
            {
                evaluations.map(evaluation => (
                    <ParagraphEvaluation
                        key={evaluation.paragraphIndex}
                        evaluation={evaluation}
                        paragraphCount={paragraphCount}
                    />
                ))
            }
        </div>
    )
}

const OverallEvaluationTab = ({ evaluations }) => {
    return (
        <div className="w-100" style={{height: "100%", overflow: "auto"}}>
            <OverallEvaluation title="好词好句" evaluation={evaluations} fieldName="wordSentence"/>
            <OverallEvaluation title="逻辑表达" evaluation={evaluations} fieldName="expression"/>
            <OverallEvaluation title="流畅度" evaluation={evaluations} fieldName="fluency"/>
            <OverallEvaluation title="修改建议" evaluation={evaluations} fieldName="suggestion"/>
            <OverallEvaluation title="教师寄语" evaluation={evaluations} fieldName=""/>
        </div>
    )
}

const SubmissionEvaluationDisplay = ({ essayText, sentenceEvaluations, paragraphEvaluations, overallEvaluations, clickSelectionValue, isModelEssay,
                                         onDeleteSentenceEvaluation, onSetSelection, onSubmitEvaluation, onIsModelEssayChange }) => {

    const {homeworkId, studentId} = useParams()

    const [api, contextHolder] = notification.useNotification()
    const navigate = useNavigate()

    const userInfoJson = sessionStorage.getItem("userInfo")
    const isTeacher = JSON.parse(userInfoJson).role.split(",").includes("teacher")

    const typeBackSubmissionHandler = () => {
        let localItem = sessionStorage.getItem("userInfo");
        const userInfo = localItem ? JSON.parse(localItem) : null;

        if (userInfo && userInfo.role.search("teacher") !== -1) {
          // 如果用户包含 teacher 角色，则执行下面的代码
          service
            .put("/api/submission/type-back", undefined, {
              params: {
                homeworkId: homeworkId,
                studentId: studentId,
              },
            })
            .then((response) => {
              if (response.data.value) {
                api.success({
                  message: "成功打回此次提交。",
                  duration: 2,
                  onClose: () => navigate(0),
                });
              } else {
                throw response;
              }
            }).catch((_) => {
              api.error({
                message: "网络异常，请稍后再试。",
              });
            });
        }
      };


    return (
        <>
            {contextHolder}
            <div className="h-100" style={{display: "flex", flexDirection: "column"}}>
                <div style={{flex: 1, padding: "1em", minHeight: 0}}>
                    <Tabs
                        style={{height: "100%"}}
                        type="card"
                        items={[
                            {
                                label: "字词句点评",
                                key: "word",
                                children: <SentenceEvaluationTab
                                essayText={essayText} evaluations={sentenceEvaluations} onDeleteSentenceEvaluation={onDeleteSentenceEvaluation}
                                clickSelectionValue={clickSelectionValue} onSetSelection={onSetSelection} />,
                                style: { height: "100%", overflowY: "auto" }
                            },
                            {
                                label: "段落点评",
                                key: "paragraph",
                                children: <ParagraphEvaluationTab
                                    evaluations={paragraphEvaluations}
                                    paragraphCount={essayText.length} />,
                                style: { height: "100%", overflowY: "auto" }
                            },
                            {
                                label: "教师总评",
                                key: "general",
                                children: <OverallEvaluationTab evaluations={overallEvaluations} />,
                                style: { height: "100%", overflowY: "auto" }
                            }
                        ]} />
                </div>
                <Row justify="end" align="middle">
                    {
                        isTeacher && (
                            <Space>
                                <Checkbox checked={isModelEssay} onChange={onIsModelEssayChange}>推荐为范文</Checkbox>
                                <Popconfirm
                                    title="打回重写"
                                    description="确定打回该同学当前所提交作文？"
                                    onConfirm={typeBackSubmissionHandler}
                                    okText="是"
                                    cancelText="否">
                                    <Button danger>打回重写</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="发布点评"
                                    description="确定提交当前点评？"
                                    onConfirm={onSubmitEvaluation}
                                    okText="是"
                                    cancelText="否">
                                    <Button type="primary">发布点评</Button>
                                </Popconfirm>

                                {/* <Button>打印</Button> */}
                            </Space>
                        )
                    }
                </Row>
            </div>
        </>
    )
}

const SubmissionDetail = () => {
    const {homeworkId, studentId} = useParams()  //使用useParams()获取页面URL中中的作业ID和学生ID,homeworkId/studentId
    const [searchParams] = useSearchParams()

    const [api, contextHolder] = notification.useNotification()
    const location = useLocation()
    const navigate = useNavigate()

    const dateFormatter = useMemo(() => new Intl.DateTimeFormat("default", {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
    }), [])

    const [essay, setEssay] = useState({
        title: "",
        currText: [],
        images: [],
        generatedImages: []
    })
    const [sentenceEvaluations, setSentenceEvaluations] = useState([])
    const [paragraphEvaluations, setParagraphEvaluations] = useState([])
    const [overallEvaluations, setOverallEvaluations] = useState({})
    const [isModelEssay, setIsModelEssay] = useState(false)

    const [evaluationOptions, setEvaluationOptions] = useState([])
    const [evaluationIndex, setEvaluationIndex] = useState(-1)

    const userInfoJson = sessionStorage.getItem("userInfo")
    const isTeacher = JSON.parse(userInfoJson).role.split(",").includes("teacher")

    const getEvaluationList = useCallback(() => {
        return service.get("/api/evaluation", {
            params: {
                homeworkId: homeworkId,
                studentId: studentId
            }
        }).then(response => response.data.value).catch(_ => {
            api.error({
                message: "网络异常，请稍后再试。"
            })
        })
    }, [location.key])

    useEffect(() => {
        getEvaluationList().then(response => {
            setEvaluationOptions(response.evaluations.map((evaluation, index) => {
                evaluation.teacherEvaluation.sentenceEvaluations.forEach(paragraphEvaluation => {
                    paragraphEvaluation.forEach(sentenceEvaluation => {
                        sentenceEvaluation.wordEvaluations.sort((word1, word2) => {
                            return word1.span[0] - word2.span[0]
                        })
                    })
                })
                return {
                    value: index,
                    label: dateFormatter.format(evaluation.submissionTime),
                    data: evaluation
                }
            }))
            if (searchParams.has("index")) {
                setEvaluationIndex(parseInt(searchParams.get("index")))
            } else {
                setEvaluationIndex(response.evaluations.length - 1)
            }
        })
    }, [getEvaluationList])

    useEffect(() => {
        if (evaluationIndex < 0 || evaluationIndex >= evaluationOptions.length) {
            return
        }
        const evaluation = evaluationOptions[evaluationIndex].data
        setEssay({
            title: evaluation.title,
            currText: evaluation.currText,
            images: evaluation.images,
            generatedImages: evaluation.generatedImages
        })
        setSentenceEvaluations(evaluation.teacherEvaluation.sentenceEvaluations)
        setParagraphEvaluations(evaluation.teacherEvaluation.paragraphEvaluations)
        setOverallEvaluations({
            description: evaluation.teacherEvaluation.description,
            score: evaluation.teacherEvaluation.score,
            tags: evaluation.teacherEvaluation.tags,
            expressionDescription: evaluation.teacherEvaluation.expressionDescription,
            expressionScore: evaluation.teacherEvaluation.expressionScore,
            expressionTags: evaluation.teacherEvaluation.expressionTags,
            fluencyDescription: evaluation.teacherEvaluation.fluencyDescription,
            fluencyScore: evaluation.teacherEvaluation.fluencyScore,
            fluencyTags: evaluation.teacherEvaluation.fluencyTags,
            wordSentenceDescription: evaluation.teacherEvaluation.wordSentenceDescription,
            wordSentenceScore: evaluation.teacherEvaluation.wordSentenceScore,
            wordSentenceTags: evaluation.teacherEvaluation.wordSentenceTags,
            suggestionDescription: evaluation.teacherEvaluation.suggestionDescription,
            suggestionTags: evaluation.teacherEvaluation.suggestionTags
        })
        setIsModelEssay(evaluation.isModelEssay)
    }, [evaluationOptions, evaluationIndex])

    const [clickSelectionValue, setClickSelectionValue] = useState("")  //状态变量，通过useState hook函数进行定义个初始化，初始值为空字符串""
    const clickSelectHandler = key => setClickSelectionValue(key)   //处理函数，接收参数key，当某个点击选择被触发时，将key的值设置为clickSelectionValue状态变量的新值，使用setClickSelectionValue函数进行更新

    const deleteSentenceEvaluationHandler = (paragraphIndex, sentenceIndex, wordIndex) => {
        if (wordIndex === undefined) {
            sentenceEvaluations[paragraphIndex][sentenceIndex] = Object.assign(sentenceEvaluations[paragraphIndex][sentenceIndex], {isGoodSent: null, label: null, type: {}})
            setSentenceEvaluations([...sentenceEvaluations])
        } else {
            sentenceEvaluations[paragraphIndex][sentenceIndex].wordEvaluations.splice(wordIndex, 1)
            sentenceEvaluations[paragraphIndex][sentenceIndex].wordEvaluations = [...sentenceEvaluations[paragraphIndex][sentenceIndex].wordEvaluations]
            setSentenceEvaluations([...sentenceEvaluations])
        }
    }

    const submitEvaluationHandler = () => {
        if (isTeacher) {
          // 如果用户包含 teacher 角色，则执行下面的代码
          const evaluation = Object.assign(evaluationOptions[evaluationIndex].data, {
            teacherEvaluation: {
              sentenceEvaluations: sentenceEvaluations,
              paragraphEvaluations: paragraphEvaluations,
              topicRelevanceScore: evaluationOptions[evaluationIndex].data.teacherEvaluation.topicRelevanceScore,
              ...overallEvaluations,
            },
            isModelEssay: isModelEssay,
          });
          service
            .put("/api/evaluation", evaluation, {
              params: {
                homeworkId: homeworkId,
                studentId: studentId,
                evaluationIndex: evaluationIndex,
              },
            })
            .then((response) => {
              if (response.data.value) {
                api.success({
                  message: "修改评论成功",
                  duration: 2,
                  onClose: () => navigate(0),
                });
              } else {
                throw response;
              }
            })
            .catch((_) => {
              api.error({
                message: "网络异常，请稍后再试。",
              });
            });
        }
      };

    const [dialogSettings, setDialogSettings] = useState({
        x: 0, y: 0, hidden: true,
        paragraphIndex: 0, sentenceIndex: 0, start: 0, end: 0
    })
    const openAddEvaluationDialogHandler = (dialogSettings) => {
        if (!isTeacher) {
            return;
        }
        const range = document.getSelection().getRangeAt(0)
        if (range.startOffset === range.endOffset) {
            setDialogSettings(Object.assign(dialogSettings, {hidden: true}))
            return
        }
        setDialogSettings(Object.assign(dialogSettings,
            {hidden: false, start: range.startOffset + dialogSettings.offset, end: range.endOffset + dialogSettings.offset}))
    }
    const addEvaluationHandler = (type, evaluation) => {
        if (type === 0) {
            const wordEvaluations = Array.from(sentenceEvaluations[dialogSettings.paragraphIndex][dialogSettings.sentenceIndex].wordEvaluations)
            wordEvaluations.push({
                span: [dialogSettings.start, dialogSettings.end],
                type: evaluation
            })
            setSentenceEvaluations(sentenceEvaluations.map((p, pi) => {
                if (pi === dialogSettings.paragraphIndex) {
                    return p.map((s, si) => si === dialogSettings.sentenceIndex ? Object.assign(s,
                        {wordEvaluations: wordEvaluations.sort((w1, w2) => w1.span[0] - w2.span[0])}) : s
                    )
                } else {
                    return p
                }
            }))
        } else if (type === 1) {
            setSentenceEvaluations(sentenceEvaluations.map((p, pi) => {
                if (pi === dialogSettings.paragraphIndex) {
                    return p.map((s, si) => si === dialogSettings.sentenceIndex ? Object.assign(s,
                        {isGoodSent: true, type: evaluation}) : s
                    )
                } else {
                    return p
                }
            }))
        }
    }

    return (
        <>
            {contextHolder}
            {
                <div className="card-container card-border" style={{
                    width: "20em", backgroundColor: "white", padding: "1em",
                    position: "absolute", top: dialogSettings.y, left: dialogSettings.x, zIndex: 10
                }} hidden={dialogSettings.hidden}>
                    <AddEvaluationDialog onSubmitEvaluation={addEvaluationHandler} onCloseDialog={() => setDialogSettings({hidden: true})} />
                </div>
            }
            <div className="w-100" style={{flex: 1, minHeight: 0, padding: "1em", display: "flex", flexDirection: "column", overflowY: "auto"}}>
                <SubmissionDetailHeader />
                <Row gutter={[8, 8]} style={{flex: 1, padding: "0.5em", minHeight: 0}}>
                    <Col style={{height: "100%"}} xs={24} lg={12} xl={15}>
                        <SubmissionEssayDisplay
                            essay={essay}
                            sentenceEvaluation={sentenceEvaluations}
                            isModelEssay={isModelEssay}
                            options={evaluationOptions}
                            selectValue={evaluationIndex}
                            selectedKey={clickSelectionValue}
                            onClickSelect={clickSelectHandler}
                            onSubmissionSelect={value => setEvaluationIndex(value)}
                            onOpenAddEvaluationDialog={openAddEvaluationDialogHandler} />
                    </Col>
                    <Col style={{height: "100%"}} xs={24} lg={12} xl={9}>
                        <SubmissionEvaluationDisplay
                            essayText={essay.currText}
                            sentenceEvaluations={sentenceEvaluations}
                            paragraphEvaluations={paragraphEvaluations}
                            overallEvaluations={overallEvaluations}
                            isModelEssay={isModelEssay}
                            clickSelectionValue={clickSelectionValue}
                            onDeleteSentenceEvaluation={deleteSentenceEvaluationHandler}
                            onIsModelEssayChange={() => setIsModelEssay(is => !is)}
                            onSetSelection={clickSelectHandler}
                            onSubmitEvaluation={submitEvaluationHandler} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

// eslint-disable-next-line
export default props => <SubmissionDetail {...props} params={useParams()} />;
