import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, Outlet, RouterProvider} from 'react-router-dom'

import './index.css';
import './App.css'

import App from './App';
import Login from './pages/Login';
import EssayManagement from "./pages/EssayManagement";
import WelcomeInfo from './pages/Welcome';
import AssignHomework from './pages/AssignHomework';
import Signup from './pages/Signup';
import UserCenter from './pages/UserCenter';
import Info from './pages/Info';
import TextRecognition from './pages/TextRecognition';

import HomeworkManagement from "./pages/HomeworkManagement";
import SubmissionDetail from "./pages/SubmissionDetail";
import SubmissionManagement from "./pages/SubmissionManagement";

import ClassManagement from './pages/ClassManagement';
import StudentList from './pages/StudentList';
import MyHomework from './pages/MyHomework';
import Guest from './pages/Guest';
import service from "./api/service";
import Logout from "./pages/Logout";
import NotFound from "./pages/NotFound";


const router = createBrowserRouter([{
    path: "/",
    element: <App />,
    handle: {
        crumb: _ => {
            return {
                path: "/",
                title: "首页"
            }
        }
    },
    children: [{
        path: "/",
        element: <WelcomeInfo />,
        handle: {
            crumb: _ => {
                return {
                    path: "",
                    title: "欢迎使用"
                }
            }
        },
    }, {
        path: "essay",
        element: <Outlet />,
        handle: {
            crumb: _ => {
                return {
                    path: "essay",
                    title: "题库管理"
                }
            }
        },
        children: [{
            index: true,
            element: <EssayManagement />
        }, {
            path: ":essayId",
            element: <AssignHomework />,
            loader: async ({params}) => {
                if (params.essayId < 0) {
                    return { id: -1, name: "空白模板" }
                }
                return service.get("/api/essay/detail", {
                    params: {
                        id: params.essayId
                    }
                }).then(response => {
                    return response.data.value
                })
            },
            handle: {
                crumb: data => {
                    return {
                        path: `${data.id}`,
                        title: data.name
                    }
                }
            }
        }]
    }, {
        path: "homework",
        element: <Outlet />,
        handle: {
            crumb: _ => {
                return {
                    path: "homework",
                    title: "作业管理"
                }
            }
        },
        children: [{
            index: true,
            element: <HomeworkManagement />,
        }, {
            path: ":homeworkId",
            element: <Outlet />,
            loader: async ({params}) => {
                return service.get("/api/homework/detail", {
                    params: {id: params.homeworkId}
                }).then(response => {
                    return response.data.value
                })
            },
            handle: {
                crumb: data => {
                    return {
                        path: `${data.id}`,
                        title: data.name
                    }
                }
            },
            children: [{
                index: true,
                element: <SubmissionManagement/>
            }, {
                path: ":studentId",
                element: <SubmissionDetail/>,
                handle: {
                    crumb: (_, {studentId}) => {
                        return {
                            path: `${studentId}`,
                            title: "作业提交历史"
                        }
                    }
                }
            }]
        }]
    }, {
        path: "modelessay/:homeworkId/:studentId",
        element: <SubmissionDetail />,
        handle: {
            crumb: (_, {homeworkId, studentId}) => {
                return {
                    path: `modelessay/${homeworkId}/${studentId}`,
                    title: "范文天地"
                }
            }
        }
    }, {
        path: "usercenter",
        element: <UserCenter />,
        handle: {
            crumb: _ => {
                return {
                    path: "usercenter",
                    title: "用户管理"
                }
            }
        }
    }, {
        path: "classManagement",
        element: <Outlet />,
        handle: {
            crumb: _ => {
                return {
                    path: "classManagement",
                    title: "班级管理"
                }
            }
        },
        children: [{
            index: true,
            element: <ClassManagement />
        }, {
            path: ":classId",
            element: <StudentList />,
            handle: {
                crumb: (_, {classId}) => {
                    return {
                        path: `${classId}`,
                        title: "班级学生列表"
                    }
                }
            }
        }]
    }, {
        path: "textRecognition/:homeworkId",
        element: <TextRecognition />,
        handle: {
            crumb: (_, {homeworkId}) => {
                return {
                    path: `textRecognition/${homeworkId}`,
                    title: "上传作业"
                }
            }
        }
    }, {
        path: "myhomework",
        handle: {
            crumb: _ => {
                return {
                    path: "myhomework",
                    title: "我的作业"
                }
            }
        },
        children: [{
            index: true,
            element: <MyHomework />
        }, {
            path: ":homeworkId/:studentId",
            element: <SubmissionDetail />,
            handle: {
                crumb: (_, {homeworkId, studentId}) => {
                    return {
                        path: `${homeworkId}/${studentId}`,
                        title: "作业提交历史"
                    }
                }
            }
        }]
    }, {
        path: "info",
        element: <Info />,
        handle: {
            crumb: _ => {
                return {
                    path: "info",
                    title: "修改个人信息"
                }
            }
        }
    }]
}, {
    path: "/login",
    element: <Login />
}, {
    path: "/logout",
    element: <Logout />
}, {
    path: "/signup",
    element: <Signup />
}, {
    path: "/guest",
    element: <Guest />
}, {
    path: "*",
    element: <NotFound />
}])
ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
