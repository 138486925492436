import {Button, Col, Row, Grid, Space, Card} from "antd";
import {createSearchParams, Link, useNavigate} from "react-router-dom";
import "../Lion.css"
import Layout, { Content } from 'antd/lib/layout/layout';
import { useState, useEffect } from 'react';
import service from "../api/service";
import {formatClassName} from "../utils";



const ModelEssay = () => {
    const navigate = useNavigate()
    let [modelEssayInfoList, setModelEssayInfoList] = useState([])

    let localItem = sessionStorage.getItem('userInfo')
    const userInfo = localItem ? JSON.parse(localItem) : null

    const cardClickHandler = info => {
        navigate({
            pathname: `/modelessay/${info.homeworkId}/${info.studentId}`,
            search: createSearchParams({index: info.index}).toString()
        })
    }

    useEffect(()=>{
        service.get("/api/submission/model_essay", {
            params: {
                studentId: userInfo.id
            }
        }).then(response => {
            setModelEssayInfoList(response.data.value)
        })
    },[])

    return (
        <Layout
            style={{
                padding: "18px"
            }}>
            <Content>
                {
                    <Row gutter={18}>{modelEssayInfoList.map(info => {
                        return <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
                            <Card
                                title={info.title}
                                bordered={false}
                                style={{
                                    marginBottom: "18px"
                                }}
                            >
                                <Row justify="start" align="bottom" gutter="20">
                                    <Col xs={24}>
                                        {/* <Row><h2>{item.name}</h2></Row> */}
                                        <Row gutter={10}>
                                            <Col xs={24}><span style={{ marginRight: "15px" }}>姓名：{info.studentName}</span></Col>
                                            <Col xs={24}><span style={{ marginRight: "15px" }}>学号：{info.studentId}</span></Col>
                                            <Col xs={24}><span style={{ marginRight: "15px" }}>班级：{formatClassName(info)}</span></Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} style={{ marginTop: "15px" }}>
                                        <Row justify="start" align="bottom">
                                            <Button onClick={() => cardClickHandler(info)} type="primary">查看原文</Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>

                        </Col>
                    })}
                    </Row>
                }
            </Content>
        </Layout>
    )
}

export default ModelEssay;
