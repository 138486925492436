import React, { useState } from 'react';
import {
    Button, Checkbox, Form, Input, Layout, message, Result, AutoComplete,
    Cascader,
    Col,
    InputNumber,
    Row,
    Select,
    DatePicker
} from 'antd';


import { EditTwoTone } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import service from '../api/service';
import { useNavigate } from 'react-router-dom';
const { Header, Footer, Sider } = Layout;
const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


const InfoForm = (props) => {
    const { userInfo } = props;
    console.log(userInfo);
    const [form] = Form.useForm();
    const Nav = useNavigate();

    const onFinish = (fieldsValue) => {
        const values = {
            ...fieldsValue,
            identity: fieldsValue.identity.join(',') // 将身份信息转换为字符串并用逗号隔开
        };
        console.log('Received values of form: ', values);
        var user = JSON.stringify({
            tel: values.tel,
            password: values.password,
            role: values.identity,
            name: values.name,
            gender: values.gender,
            id: userInfo.id
        });
        console.log('json: ', user);
        service({
            method: "post",
            url: "/api/userManagement/changeUserInfoByAdmin",
            data: user,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }

        )
            .then(res => {
                message.success('修改成功');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch(err => {
                message.error(err.response.data);
            })

    };
    return (<Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{
            tel: userInfo.tel,
            password: userInfo.password,
            confirm: userInfo.password,
            name: userInfo.name,
            gender: userInfo.gender.toString(),
            identity: userInfo.role.split(','),
        }}
        scrollToFirstError
    >
        <Form.Item
            name="tel"
            label="手机号码"
            rules={[
                {
                    required: true,
                    message: '请输入手机号!',
                },
            ]}
        >
            <Input
                // addonBefore={prefixSelector}
                style={{
                    width: '100%',
                }}
            />
        </Form.Item>

        <Form.Item
            name="password"
            label="密码"
            tooltip="密码长度至少6位"
            rules={[
                {
                    required: true,
                    message: '请输入密码!',
                },
                () => ({
                    validator(_, value) {
                        if (value.length >= 6) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('密码需要至少6位!'));
                    },
                }),
            ]}
            hasFeedback
        >
            <Input.Password />
        </Form.Item>

        <Form.Item
            name="confirm"
            label="确认密码"
            dependencies={['password']}
            hasFeedback
            rules={[
                {
                    required: true,
                    message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('两次输入的密码不一致!'));
                    },
                }),
            ]}
        >
            <Input.Password />
        </Form.Item>

        <Form.Item
            name="name"
            label="真实姓名"
            rules={[
                {
                    required: true,
                    message: '请填写你的姓名!',
                    whitespace: true,
                },
            ]}
        >
            <Input />
        </Form.Item>




        <Form.Item
            name="gender"
            label="性别"
            rules={[
                {
                    required: true,
                    message: '请选择性别!',
                },
            ]}
        >
            <Select placeholder="请选择性别" optionLabelProp="children">
                <Option value="1">男</Option>
                <Option value="0">女</Option>
            </Select>
        </Form.Item>

        <Form.Item
            name="identity"
            label="权限"
            rules={[
                {
                    required: true,
                    message: '请选择权限!',
                },
            ]}
        >
            <Select
                placeholder="请选择权限"
                mode="multiple"
            >
                <Option value="admin">admin</Option>
                <Option value="teacher">teacher</Option>
                <Option value="student">student</Option>
            </Select>
        </Form.Item>


        <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
                修改
            </Button>
        </Form.Item>
    </Form>)

}
export default InfoForm;