import { HomeOutlined, FolderOpenOutlined, FileTextOutlined, UsergroupAddOutlined, EditOutlined, SmileOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, Tooltip, Avatar } from 'antd';
import React from 'react';
import {Outlet, useNavigate, useMatches, Navigate} from "react-router-dom"
import Breadcrumbs from "./components/Breadcrumbs";
const { Header, Footer, Sider } = Layout;

const menus = [{
    key: "/",
    icon: <HomeOutlined />,
    label: <span>首页</span>
}, {
    icon: <UsergroupAddOutlined />,
    label: <span>教师功能</span>,
    role: ["teacher"],
    children: [{
        key: "/classManagement",
        icon: <UsergroupAddOutlined />,
        label: <span>班级管理</span>
    }, {
        key: "/essay",
        icon: <FolderOpenOutlined />,
        label: <span>题库管理</span>
    }, {
        key: "/homework",
        icon: <FileTextOutlined />,
        label: <span>作业管理</span>
    }]
}, {
    icon: <UsergroupAddOutlined />,
    label: <span>管理员功能</span>,
    role: ["admin"],
    children: [{
        key: "/usercenter",
        icon: <FolderOpenOutlined />,
        label: <span>用户管理</span>
    }]
}, {
    key: "/myhomework",
    icon: <FileTextOutlined />,
    role: ["student"],
    label: <span>我的作业</span>
}]

/**
 * 根据权限控制菜单
 * "identity"这一项有三种; admin teacher student
 * 管理员工具: 只对管理员显示 / 班级管理、题库管理：只对教师显示 /未登录的用户自动跳转到登录页面
 * */
const generateDynamicMenu = () => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}")
    if (!userInfo.role) {
        return []
    }
    return menus.filter(menu => {
        return userInfo.role.split(",").some(r => !menu.role || menu.role.includes(r))
    })
}
const generateDynamicMenuOnPhone = () => {
    return generateDynamicMenu()
        .map(menu => menu.children ? menu.children : menu)
        .flat(1)
}

const App = () => {
    // 根据权限控制菜单
    const navigate = useNavigate();
    const matches = useMatches()

    const menus = generateDynamicMenu()
    if (!menus.length) {
        return <Navigate to="/logout" />
    }
    const menuClickHandler = ({key}) => navigate(key)

    const selectedMenu = matches.length === 1 ? matches[0].pathname : matches[1].pathname

    return <Layout style={{ height: "100%" }}>
        <Header className="site-layout-sub-header-background" >
            <h1>

                {/* <Button type="text" size='large' className='mobile'
                    style={{ display: "inline-block", verticalAlign: "0px", marginRight: "3px", width: "36px" }}
                    icon={<MenuOutlined />}
                    onClick={() => { setDrawderMenuOpen(true) }}>
                </Button> */}
                <Avatar src={<img src={"/elion.png"} alt="avatar" />} style={{ marginLeft: "12px" }} />
                <span style={{ marginLeft: "6px" }}>小花狮作文智能辅导系统</span>
                <Tooltip title="修改个人信息" className='desktop'>
                    <Button shape="circle" icon={<EditOutlined />} style={{ float: "right", marginTop: "0.8%" }} onClick={() => {

                        navigate('/info');
                    }}>
                    </Button>
                </Tooltip>

            </h1>

        </Header>
        <Layout>
            {/* <Drawer
                placement={"left"}
                open={drawderMenuOpen}
                onClose={() => { setDrawderMenuOpen(false) }}
                bodyStyle={{ padding: "0px" }}
                headerStyle={{ padding: "0px" }}
                width={240}
                closeIcon={<Button type="text" size='large' icon={<MenuFoldOutlined />} ></Button>}
            >
                <Menu
                    theme="light"
                    mode="inline"
                    selectedKeys={[pathname]}
                    items={menus}
                    onClick={(e) => { onClick(e); setDrawderMenuOpen(false) }}
                />
                <div className="clear-local-storage-and-cookie mobile-only" >
                    <ClearLocalStorageAndCookie />
                </div>
        </Drawer> */}
            <Sider
                theme='light'
                breakpoint="md"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                collapsible={true}
                defaultCollapsed={true}
                trigger={null}
            >
                <Menu
                    theme="light"
                    mode="inline"
                    selectedKeys={selectedMenu}
                    items={menus}
                    onClick={menuClickHandler}
                />
                <div className="clear-local-storage-and-cookie desktop">
                    <Button onClick={() => navigate("/logout")} danger>退出登录</Button>
                </div>
            </Sider>
            <Layout style={{ backgroundColor: '#f5f5f5', overflowY: "scroll" }}>
                <Breadcrumbs />
                {/*<Breadcrumb className='desktop' separator=">" items={breadcrumbItems} style={{ marginLeft: '15px', marginTop: "10px" }} />*/}
                <Outlet />
            </Layout>

        </Layout>

        <Footer style={{ textAlign: 'center', padding: "5px 10px", backgroundColor: "white" }} >
            <span
                className='desktop'>小花狮 ©2022 Created by CubeNLP</span>
            <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={selectedMenu}
                disabledOverflow={true}
                items={generateDynamicMenuOnPhone()}
                onClick={menuClickHandler}
                style={{ justifyContent: "space-around" }}
                className='bottom-menu mobile-flex'
            />
        </Footer>
    </Layout>
};

export default App;
