// export function formatClassName(data) {
//     return `${(new Date().getFullYear() - data.enrollmentYear + 1)}年级${data.num}班`
// }


export function formatClassName(data) {
    return `${(data.enrollmentYear)}年${data.num}班`
}

export function formatDate(date){
    const dateFormatter = new Intl.DateTimeFormat("default", {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
    })
    try{
        date = new Date(date)
        return dateFormatter.format(date)
    }catch{
        return "--"
    }
}
export function getUserId(){
    let localItem = sessionStorage.getItem('userInfo')
    return localItem ? JSON.parse(localItem).id : null
}