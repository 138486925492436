import React from 'react';
import { Space, Table, Tag, message, Input, Button, Popconfirm } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useState, useEffect, useRef } from 'react';
import ChangeUserInfo from '../components/ChangeUserInfo';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import NewClass from '../components/newClass';
import mockClass from './mockClass';
import {formatClassName} from "../utils";

import service from '../api/service';
import {useNavigate} from "react-router-dom";

export default () => {

    let [data, setData] = useState([]);
    let [total, setTotal] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const navigate = useNavigate()
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };


    const columns = [
        {
            title: '班级号',
            dataIndex: 'num',
            key: 'num',
            render: (name, record) => <p>  {formatClassName(record)} </p>,
            // ...getColumnSearchProps('name'),
        },
        {
            title: '入学年份',
            key: 'enrollmentYear',
            dataIndex: 'enrollmentYear',
        },
        {
            title: '班级邀请码',
            dataIndex: 'code',
            key: 'code',
        },


        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => navigate(`/classManagement/${record.id}`)}>
                        查看学生列表
                    </Button>
                </Space>
            ),
        },
    ];
    const confirm = (id) => {
        service({
            method: "delete",
            url: "/api/userManagement/deleteUser",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                id: id
            }
        }

        )
            .then(res => {
                message.success(res.data);
            })
            .catch(err => {

            })

    };


    window.timer = null;
    useEffect(() => {
        clearTimeout(window.timer);
        window.timer = setTimeout(() => {
            showTable();
        }, 0)
    }, [])

    const showTable = () => {
        let localItem = sessionStorage.getItem('userInfo')
        const userInfo = localItem ? JSON.parse(localItem) : null
        service({
            method: "get",
            url: "/api/class",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }

        )
            .then(res => {
                console.log('hello');
                let classes = res.data;
                let total = classes.length;
                classes.forEach((r, i) => {
                    r.key = i;
                });
                console.log(classes);
                setData(classes);
                setTotal(total);
                sessionStorage.setItem('classes', JSON.stringify(classes));
            })
            .catch(err => {

            });
    }
    return (
        <Layout>
            <Header className="site-layout-sub-header-background" style={{ backgroundColor: '#fafafa' }}>
                <NewClass />
            </Header>
            <Content>
                <Table columns={columns} dataSource={data} pagination={true} />
            </Content>
        </Layout>
    )

};
