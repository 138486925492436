import React, { useState } from 'react';
import {
    Button, Checkbox, Form, Input, Layout, message, Result, AutoComplete,
    Cascader,
    Col,
    InputNumber,
    Row,
    Select,
    DatePicker, Tooltip,
    Avatar
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';


import { EditTwoTone } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import service from '../api/service';
import { useNavigate } from 'react-router-dom';


const { Header, Footer, Sider } = Layout;
const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 10,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


const Signup = () => {
    const [form] = Form.useForm();
    const Nav = useNavigate();
    const onFinish = (fieldsValue) => {
        const values = {
            ...fieldsValue
        };
        console.log('Received values of form: ', values);
        var user = JSON.stringify({
            tel: values.tel,
            password: values.password,
            role: "student",
            name: values.name,
            gender: values.gender,
        });
        console.log('json: ', user);
        service({
            method: "post",
            url: "/api/signupsys/signupStudent",
            data: user,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                class_id: values.classId
            }
        })

            .then(res => {
                message.success(res.data);
                Nav('/login');
            })
            .catch(err => {
                message.error(err.response.data);
            })

    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );

    return (
        <Layout style={{ height: "100%" }}>
            <Header className="site-layout-sub-header-background" >
            <h1>
                <Avatar src={<img src={"/elion.png"} alt="avatar" />} style={{ marginLeft: "12px" }} />
                <span style={{ marginLeft: "6px" }}>小花狮作文智能辅导系统</span>
            </h1>
            </Header>
            <Content >
                <div style={{ margin: "auto", width: "90%", marginTop: "10%" }}>
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="register"
                        onFinish={onFinish}
                        initialValues={{
                        }}
                        scrollToFirstError
                    >
                        <Row justify="center">
                            <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                                <Form.Item name="tel" label="手机号码" rules={[
                                    {
                                        required: true,
                                        message: '请输入正确的手机号!',
                                        pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/
                                    },
                                ]}>
                                    <Input
                                        maxLength={11}
                                        placeholder="请填写手机号码"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="密码"
                                    tooltip="密码长度至少6位"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入密码!',
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (value.length >= 6) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('密码需要至少6位!'));
                                            },
                                        }),
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label="确认密码"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',

                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('两次输入的密码不一致!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    name="name"
                                    label="真实姓名"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请填写你的姓名!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>


                                <Form.Item
                                    name="classId"
                                    label="班级邀请码"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入教师提供的班级码!',
                                        },
                                    ]}
                                >
                                    <Input
                                        // addonBefore={prefixSelector}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                    {/* <Tooltip title="班级码由教师提供">
                  <QuestionCircleOutlined />
                </Tooltip> */}
                                </Form.Item>
                                {/* <Form.Item label="验证码" >
                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="captcha"
                                                noStyle
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入验证码!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Button>获取验证码</Button>
                                        </Col>
                                    </Row>
                                </Form.Item> */}

                                <Form.Item {...tailFormItemLayout}>
                                    <Button type="primary" htmlType="submit">
                                        注册
                                    </Button>
                                </Form.Item>
                                <Form.Item {...tailFormItemLayout}>
                                    <Button type="link" href={'/guest'}>
                                        没有班级码?以访客身份注册
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Content>
        </Layout >
    );
};

export default Signup;
