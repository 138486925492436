import {Cascader} from "antd";

const WordSentenceEvaluationSelection = ({ onEvaluationChanged, selectedValue }) => {
    const options = [{
        value: "作文亮点",
        label: "作文亮点",
        children: [{
            value: "语言通畅",
            label: "语言通畅",
            children: [{
                value: "词句多样",
                label: "词句多样",
            }, {
                value: "情感充沛",
                label: "情感充沛"
            }, {
                value: "语言优美",
                label: "语言优美"
            }, {
                value: "生动形象",
                label: "生动形象"
            }, {
                value: "记叙完整",
                label: "记叙完整"
            }, {
                value: "刻画细腻",
                label: "刻画细腻"
            }, {
                value: "详略得当",
                label: "详略得当"
            }, {
                value: "善用修辞",
                label: "善用修辞"
            }, {
                value: "语句精炼",
                label: "语句精炼"
            }]
        }, {
            value: "选词恰当",
            label: "选词恰当",
            children: [{
                value: "成语",
                label: "成语"
            }, {
                value: "叠词",
                label: "叠词"
            }]
        }]
    }, {
        value: "还需努力",
        label: "还需努力",
        children: [{
            value: "错别字错误",
            label: "错别字错误"
        }, {
            value: "缺字漏字",
            label: "缺字漏字"
        }, {
            value: "缺少标点",
            label: "缺少标点"
        }, {
            value: "错用标点",
            label: "错用标点"
        }, {
            value: "成分残缺型错误",
            label: "成分残缺型错误",
            children: [{
                value: "主语不明",
                label: "主语不明"
            }, {
                value: "谓语残缺",
                label: "谓语残缺"
            }, {
                value: "宾语残缺",
                label: "宾语残缺"
            }, {
                value: "其他成分残缺",
                label: "其他成分残缺"
            }]
        }, {
            value: "成分赘余型错误",
            label: "成分赘余型错误",
            children: [{
                value: "主语多余",
                label: "主语多余"
            }, {
                value: "虚词多余",
                label: "虚词多余"
            }, {
                value: "语句重复",
                label: "语句重复"
            }, {
                value: "其他成分多余",
                label: "其他成分多余"
            }]
        }, {
            value: "成分搭配不当型错误",
            label: "成分搭配不当型错误",
            children: [{
                value: "主谓搭配不当",
                label: "主谓搭配不当"
            }, {
                value: "动宾搭配不当",
                label: "动宾搭配不当"
            }, {
                value: "语序不当",
                label: "语序不当"
            }, {
                value: "其他搭配不当",
                label: "其他搭配不当"
            }]
        }, {
            value: "不合逻辑",
            label: "不合逻辑",
            children: [{
                value: "不合语言逻辑",
                label: "不合语言逻辑"
            }, {
                value: "不合事实逻辑",
                label: "不合事实逻辑"
            }]
        }]
    }]

    const selectionChangeHandler = value => {
        let temp = {}
        value?.forEach((v, i) => {
            temp[`level${i+1}`] = v
        })
        onEvaluationChanged(temp)
    }

    return (
        <Cascader options={options} value={selectedValue} changeOnSelect expandTrigger="hover" onChange={selectionChangeHandler} />
    )
}

export default WordSentenceEvaluationSelection;
