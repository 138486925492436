import React from 'react';
import { Link } from "react-router-dom";
import { Space, Table, Tag, message, Input, Button, Popconfirm, Modal, Row, Col, Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import service from '../api/service';
import { useState, useEffect, useRef } from 'react';
import ChangeUserInfo from '../components/ChangeUserInfo';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import HomeworkInfo from './HomeworkInfo';


export default () => {
    let localItem = sessionStorage.getItem('userInfo')
    const userInfo = localItem ? JSON.parse(localItem) : null
    let [data, setData] = useState([]);
    const searchInput = useRef(null);



    const columns = [
        {
            title: '作业名称',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => <p>  {name} </p>,
            // ...getColumnSearchProps('name'),
        },
        {
            title: '发布时间',
            key: 'startTime',
            dataIndex: 'startTime',
            // render: (identity) => {
            //     var color = identity === 'teacher' ? 'geekblue' : 'green';

            //     if (identity === 'admin') {
            //         color = 'volcano';
            //     };

            //     return (
            //         <Tag color={color} key={identity}>
            //             {identity.toUpperCase()}
            //         </Tag>
            //     )
            // }
        },


        {
            title: '作业描述',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '完成情况',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {

                if (status === 0) {
                    return (
                        <Tag color={'volcano'} key={status}>
                            {'未提交'}
                        </Tag>
                    )
                };

                if (status === 1) {
                    return (
                        <Tag color={'geekblue'} key={status}>
                            {'未复批'}
                        </Tag>
                    )
                };
                if (status === 2) {
                    return (
                        <Tag color={'green'} key={status}>
                            {'已复批'}
                        </Tag>
                    )
                };
                if (status === 3) {
                    return (
                        <Tag color={'yellow'} key={status}>
                            {'打回'}
                        </Tag>
                    )
                };
            }
        },



        {
            title: '操作',
            key: 'action',
            render: (text) => (
                <Button type="primary" >
                    查看批改结果
                </Button >
            ),
        },
    ];


    window.timer = null;
    useEffect(() => {
        clearTimeout(window.timer);
        window.timer = setTimeout(() => {
            showTable();
        }, 0)
    }, [])




    const showTable = () => {

        service({
            method: "get",
            url: "/api/homework/studentHomework",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                id: userInfo.id
            }
        }).then(res => {
            console.log('hello');
            let homeworks = res.data;
            let total = homeworks.length;
            homeworks.forEach((r, i) => {
                r.key = i;
            });
            homeworks = homeworks.filter(i => {
                // return i.status == 1 || i.status == 2;
                return i.status != 0;
            })
            // console.log(homeworks);
            // homeworks = [homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0],homeworks[0]]
            setData(homeworks);
        })
            .catch(err => {

            });
    }

    const getStatusIcon = (status) => {

        if (status === 0) {
            return (
                <Tag color={'volcano'} key={status}>
                    {'未提交'}
                </Tag>
            )
        };

        if (status === 1) {
            return (
                <Tag color={'geekblue'} key={status}>
                    {'未复批'}
                </Tag>
            )
        };
        if (status === 2) {
            return (
                <Tag color={'green'} key={status}>
                    {'已复批'}
                </Tag>
            )
        };
        if (status === 3) {
            return (
                <Tag color={'yellow'} key={status}>
                    {'打回'}
                </Tag>
            )
        };
    }


    return (
        <Layout
            style={{
                padding: "18px"
            }}>
            <Content>
                {
                    <Row gutter={18}>{data.map((item) => {
                        return <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
                            <Card
                                title={item.name}
                                bordered={false}
                                style={{
                                    marginBottom: "18px"
                                }}
                            >
                                <Row justify="start" align="bottom" gutter="20">
                                    <Col xs={24} style={{maxHeight:"140px", minHeight:"140px", overflowY:"scroll"}}>
                                        {/* <Row><h2>{item.name}</h2></Row> */}
                                        <Row gutter={10}>
                                            <Col xs={24}><span style={{ marginRight: "15px" }}>发布时间：{item.startservice}</span></Col>
                                            <Col xs={24}><span style={{ marginRight: "15px" }}>写作要求：{item.description}</span></Col>
                                            {
                                                item.note && <Col xs={24}><span style={{ marginRight: "15px" }}>备注：{item.note}</span></Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col xs={24} style={{ marginTop: "15px" }}>
                                        <Row gutter="10" justify="left">
                                            <Col>
                                                <Row>状态</Row>
                                                {getStatusIcon(item.status)}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} style={{ marginTop: "15px" }}>
                                        <Row justify="start" align="bottom">
                                            <Link to={`${item.id}/${userInfo.id}`}>
                                                <Button type="primary">查看详情</Button>
                                            </Link>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>

                        </Col>
                    })}
                    </Row>
                }
            </Content>
        </Layout>
    )
};
