import {Button, Tabs} from 'antd';
import InCourseEssays from "./InCourseEssays";
import OutCourseEssays from "./OutCourseEssays";
import {useNavigate} from "react-router-dom";

const EssayManagement = () => {
    const navigate = useNavigate();

    return (
        <div style={{margin: "2em"}}>
            <Tabs
                items={[{
                    label: "课内题库",
                    key: "inCourse",
                    children: <InCourseEssays />
                }, {
                    label: "写作训练题库",
                    key: "essay",
                    disabled: true
                }, {
                    label: "课外题库",
                    key: "outCourse",
                    children: <OutCourseEssays />
                }]}
                tabBarExtraContent={<Button type="primary" onClick={() => navigate("-1")}>布置作业</Button>}
            />
        </div>
    )
}

export default EssayManagement;
