import {Link, useParams} from "react-router-dom";
import {Button, Col, Form, Input, notification, Radio, Row, Space, Table, Tabs} from "antd";
import {useEffect, useState} from "react";
import service from '../api/service';
import {formatClassName} from "../utils";
import HomeworkStats from "../components/HomeworkStats";
import SubmissionStatusTag from "../components/SubmissionStatusTag";

import { formatDate } from "../utils";
import WritingScoreAnalysis from "../components/WritingScoreAnalysis";


const { TextArea } = Input;


const SubmissionManagementHeader = ({ homework }) => {
    return(
        <Row className="w-100" style={{padding: "1em"}} justify="start" align="top" gutter={[20, 20]}>
            <Col xs={12} lg={6}>
                <div style={{color: "grey"}}>作业名称</div>
                <b>{homework.name}</b>
            </Col>
            <Col xs={12} lg={6}>
                <div style={{color: "grey"}}>班级</div>
                <div style={{color: "grey"}}>{formatClassName(homework)}</div>
            </Col>
            <Col xs={24} sm={13} lg={9} xl={9} xxl={10}>
                <HomeworkStats data={homework} />
            </Col>
            {/*<Col xs={10} lg={3} xxl={2}>*/}
            {/*    <Button>催交</Button>*/}
            {/*</Col>*/}
        </Row>
    )
}

const StudentSubmissionTab = ({ dateFormatter }) => {
    const {homeworkId} = useParams()

    const [submissionType, setSubmissionType] = useState(4)
    const [submissions, setSubmissions] = useState([])

    const [api, contextHolder] = notification.useNotification();

    const columns = [{
        title: "序号",
        dataIndex: "rank",
        key: "rank",
        width: "5em"
    }, {
        title: "姓名",
        dataIndex: "name",
        key: "name",
        width: "10em"
    }, {
        title: "得分",
        dataIndex: "score",
        key: "score",
        width: "5em"
    }, {
        title: "作文题目",
        dataIndex: "title",
        key: "title"
    }, {
        title: "写作能力评价",
        dataIndex: "comment",
        key: "comment"
    }, {
        title: "修改次数",
        dataIndex: "submissionNum",
        key: "submissionNum"
    }, {
        title: "终稿提交时间",
        dataIndex: "updateTime",
        key: "updateTime",
        render: (_, record) => {
            if (record.status !== 0) {
                return <div>{formatDate(record.updateTime)}</div>
            }
        }
    }, {
        title: "状态",
        key: "status",
        dataIndex: "status",
        render: (_, record) => <SubmissionStatusTag status={record.status} />
    }, {
        title: "操作",
        key: "action",
        render: (_, record) => {
            if (record.status !== 0) {
                return (
                    <Space size="small">
                        <Link to={`${record.studentId}`}>查看</Link>
                        <Button type="link">打印</Button>
                    </Space>
                )
            }
        }
    }]

    useEffect(() => {
        service.get("/api/submission/list", {
            params: {
                homeworkId: homeworkId,
                type: submissionType
            }
        }).then(response => {
            setSubmissions(response.data.value.map((submission, index) => {
                submission.rank = index + 1
                return submission
            }))
        }).catch(_ => {
            api.error({
                message: "网络出现异常，请稍后重试。"
            })
        })
    }, [submissionType])

    return (
        <>
            {contextHolder}
            <div style={{width: "100%"}}>
                <div style={{width: "100%", display:"flex", justifyContent: "end", padding: "0.25em"}}>
                    <Radio.Group value={submissionType} onChange={e => setSubmissionType(e.target.value)}>
                        <Radio value={0}>仅显示未完成</Radio>
                        <Radio value={1}>仅显示待复批</Radio>
                        <Radio value={4}>显示全部</Radio>
                    </Radio.Group>
                </div>
                <Table
                    style={{padding: "0.25em"}}
                    dataSource={submissions}
                    columns={columns}
                    rowKey="studentId"
                />
            </div>
        </>
    )
}

const HomeworkRequirementTab = ({ dateFormatter }) => {
    const {homeworkId} = useParams()

    const [loading, setLoading] = useState(false)

    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()

    useEffect(() => {
        setLoading(true)
        service.get("/api/homework/detail", {
            params: {
                id: homeworkId
            }
        }).then(response => {
            if (!response.data.value) {
                throw response
            }
            form.setFieldsValue(Object.assign({}, response.data.value))
        }).catch(() => {
            api.error({
                message: "您没有访问该作业的权限。"
            })
        }).finally(() => setLoading(false))
    }, [])

    const updateHomeworkNote = value => {
        service.put("/api/homework", {
            id: homeworkId,
            note: value.note
        }).then(response => {
            if (response.data.value) {
                api.success({
                    message: "更新作业备注成功。",
                    duration: 2
                })
            } else {
                throw response
            }
        }).catch(() => api.error({
            message: "网络异常，请稍后再试。"
        }))
    }

    return (
        <>
            {contextHolder}
            <Form
                form={form}
                style={{width: "60%", maxWidth: "50em", margin: "auto"}}
                labelCol={{sm: 10, md: 8, lg: 6, xxl: 3}}
                onFinish={updateHomeworkNote}>
                <Form.Item label="作业名称" name="name">
                    <span>{form.getFieldValue("name")}</span>
                </Form.Item>
                <Form.Item label="班级" name="className">
                    <span>{formatClassName(form.getFieldsValue(["enrollmentYear", "num"]))}</span>
                </Form.Item>
                <Form.Item label="截止时间" name="endTime">
                    <span>{form.getFieldValue("endTime") ? dateFormatter.format(new Date(form.getFieldValue("endTime"))) : ""}</span>
                </Form.Item>
                <Form.Item label="写作要求" name="description">
                    <span>{form.getFieldValue("description")}</span>
                </Form.Item>
                <Form.Item label="备注" name="note">
                    <TextArea rows={4} placeholder="可在此多次添加备注" value={form.getFieldValue("note")} />
                </Form.Item>
                <Form.Item wrapperCol={{sm: {offset: 12}}}>
                    <Button loading={loading} htmlType="submit">修 改 备 注</Button>
                </Form.Item>
            </Form>
        </>
    )
}

const SubmissionManagement = () => {
    const dateFormatter = new Intl.DateTimeFormat("default", {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
    })

    const {homeworkId} = useParams()

    const [homework, setHomework] = useState({})

    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        service.get("/api/homework/detail", {
            params: {
                id: homeworkId
            }
        }).then(response => {
            if (!response.data.value) {
                throw response
            }
            setHomework(response.data.value)
        }).catch(() => {
            api.error({
                message: "您没有访问该作业的权限。"
            })
        })
    }, [])

    return (
        <>
            {contextHolder}
            <div style={{width: "100%", flex: 1, minHeight: 0, padding: "1em", display: "flex", flexDirection: "column"}}>
                <SubmissionManagementHeader homework={homework} />
                <div style={{width: "100%", padding: "1em", flex: 1, minHeight: 0}}>
                    <Tabs
                        style={{height: "100%"}}
                        items={[{
                            label: "学生作业详情",
                            key: "detail",
                            children: <StudentSubmissionTab dateFormatter={dateFormatter} />,
                            style: { height: "100%", overflowY: "auto" }
                        }, {
                            label: "作业要求",
                            key: "requirement",
                            children: <HomeworkRequirementTab dateFormatter={dateFormatter} />,
                            style: { height: "100%", overflowY: "auto" }
                        }, {
                            label: "写作成绩分析",
                            key: "analysis",
                            children: <WritingScoreAnalysis homeworkName={homework.name} className={formatClassName(homework)} />,
                            style: { height: "100%" }
                        }]}
                    />
                </div>
            </div>
        </>
    )
}

// eslint-disable-next-line
export default props => <SubmissionManagement {...props} params={useParams()} />;
