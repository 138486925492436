import {Button, Col, Row, Grid, Space, Popconfirm} from "antd";
import { Typography,Tag, message } from 'antd';

import "../Lion.css"
import {useNavigate} from "react-router-dom";
import {formatClassName} from "../utils";
import {formatDate} from "../utils";
import HomeworkStats from "./HomeworkStats";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import React, {useCallback} from 'react';

const { useBreakpoint } = Grid;
const { Title } = Typography;

const HomeworkInfo = ({ data, dateFomatter }) => {
    const screens = useBreakpoint();

    return (
        <>
            <Row>
                <span style={{color: "grey"}}>{formatClassName(data)}</span>
            </Row>
            <Row>
                <Title level={3}>{data.name}</Title>
            </Row>
            <Row gutter={[8, 8]} justify="start" align="middle">
                {
                    screens.xl ? (
                        <Space style={{display: "flex", alignItems: "center"}}>
                            {
                                Date.now() < new Date(data.endTime) ?
                                    <Tag color="success">进行中</Tag> :
                                    <Tag color="error">已截止</Tag>
                            }
                            {/* <span>{`发布时间：${dateFomatter.format(Date.parse(data.startTime))}`}</span>
                            <span>{`截止时间：${dateFomatter.format(Date.parse(data.endTime))}`}</span> */}
                            <span>{`发布时间：${formatDate(data.startTime)}`}</span>
                            <span>{`截止时间：${formatDate(data.endTime)}`}</span>
                        </Space>
                    ) : (
                        <>
                            <Col xs={24} md={24} lg={3}>
                                {
                                    Date.now() < new Date(data.endTime) ?
                                        <Tag color="success">进行中</Tag> :
                                        <Tag color="error">已截止</Tag>
                                }
                            </Col>
                            {/* <Col xs={24} md={12} lg={8}>
                                <span>{`发布时间：${dateFomatter.format(Date.parse(data.startTime))}`}</span>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <span>{`截止时间：${dateFomatter.format(Date.parse(data.endTime))}`}</span>
                            </Col> */}
                            <Col xs={24} md={12} lg={8}>
                                <span>{`发布时间：${formatDate(data.startTime)}`}</span>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <span>{`截止时间：${formatDate(data.endTime)}`}</span>
                            </Col>
                        </>
                    )
                }
            </Row>
        </>
    )
}

const Homework = ({ data, dateFomatter }) => {
    const navigate = useNavigate();

    // const handleDelete = useCallback(() => {
    //     axios({
    //         method: "delete",
    //         url: "/api/homework/deleteHomework",
    //         headers: {
    //             'Content-Type': 'application/json;charset=UTF-8'
    //         },
    //         params: {
    //             id: data.id
    //         }
    //     })
    //     .then(res => {
    //         message.success(res.data);
    //         window.location.reload();
    //     })
    //     .catch(err => {
    //         message.error(err.response.data);
    //         window.location.reload();
    //     });
    // }, [data.id]);

    const confirm = (id) => {
        axios({
            method: "delete",
            url: "/api/homework/deleteHomework",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            params: {
                id: id
            }
        }

        )
            .then(res => {
                message.success(res.data);
                window.location.reload();
            })
            .catch(err => {
                message.error(err.response.data);
                // window.location.reload();
            })

    };


    return (
        <Row justify="start" align="bottom" gutter={[8, 8]}
             className="card-border card-container" style={{backgroundColor: "white",  padding: "1em"}}>

            <Col xs={24} sm={24} md={24} xl={14} xxl={17}>
                <HomeworkInfo data={data} dateFomatter={dateFomatter} />
            </Col>
            <Col xs={24} sm={14} md={14} xl={7} xxl={5}>
                <HomeworkStats data={data} />
            </Col>
            <Col xs={24} sm={10} md={10} xl={3} xxl={2}>
                <Button style={{backgroundColor: "yellowgreen", color: "white", marginBottom:"0.25em"}}
                        onClick={() => navigate(`/homework/${data.id}`)}>查看详情</Button>
            </Col>

            {/* Add the delete button with Popconfirm */}
            <Col style={{ position: "absolute", top: 10, right: 10 }}>
                <Popconfirm
                    title="确定要删除这个作业吗？"
                    onConfirm={confirm.bind(this, data.id)}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="text" icon={<DeleteOutlined />}
                />
                </Popconfirm>
            </Col>
        </Row>
    )
}

export default Homework;
