import React from 'react';
import { Col, Row, Table, Space, Button } from "antd";
import { blue, green } from "@ant-design/colors";
import service from '../api/service';
import { Link } from "react-router-dom";
import HomeworkInfo from './HomeworkInfo';

const handleEssayHomeworkId=(record)=>{
    console.log(record)
    service.get("/api/homework/get_practice_id?essayId="+record.id, {
        params: {
            essayId: record.id,
        }
    })
        .then(response => {
            window.location.href = `/textRecognition/${response.data.value}`;
        })
}

class InCourseHomework extends React.Component {

    constructor(props) {
        super(props);

        this.tableColumns = [{
            title: "作业名称",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: "20%"
        }, {
            title: "写作要求概述",
            dataIndex: "description",
            key: "description",
            align: "center",
            ellipsis: true
        }, {
            title: "操作",
            key: "action",
            align: "center",
            width: "15%",
            render: (_, record) =>(
                <Button onClick={() => handleEssayHomeworkId(record)}>提交写作</Button>
            ),
            //  <Link style={{
            //     "color": green.primary
            // }} to={`/textRecognition/${record.id}`}>提交写作</Link>
        }]
        this.state = {
            textbookVersions: [],
            grades: [],
            essays: [],
            textbookVersionSelected: 0,
            gradeSelected: 0
        }
    }
    render() {
        return (
            <div>
                <div style={{ width: "100%" }}>
                    <span>教版：</span>
                    {
                        this.state.textbookVersions.map(textbookVersion => (
                            <span onClick={() => this.onTextbookVersionClicked(textbookVersion.id)} style={(() => {
                                if (textbookVersion.id === this.state.textbookVersionSelected) {
                                    return {
                                        color: blue.primary,
                                        marginLeft: "1em",
                                        cursor: "pointer"
                                    }
                                } else {
                                    return {
                                        marginLeft: "1em",
                                        cursor: "pointer"
                                    }
                                }
                            })()} key={textbookVersion.id}>{textbookVersion.name}</span>
                        ))
                    }
                </div>
                <div style={{ marginTop: "0.5em" }}>
                    <span>年级：</span>
                    {
                        this.state.grades.map(grade => (
                            <span onClick={() => this.onGradeClicked(grade.id)} style={(() => {
                                if (grade.id === this.state.gradeSelected) {
                                    return {
                                        color: blue.primary,
                                        marginLeft: "1em",
                                        cursor: "pointer"
                                    }
                                } else {
                                    return {
                                        marginLeft: "1em",
                                        cursor: "pointer"
                                    }
                                }
                            })()} key={grade.id}>{grade.name}</span>
                        ))
                    }
                </div>
                <div style={{ marginTop: "0.5em" }}>
                    <Table style={{
                        width: "100%",
                        paddingInline: "4em"
                    }} dataSource={this.state.essays} columns={this.tableColumns}></Table>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.getTextbookVersionList()
    }

    getGradeList() {
        service.get("/api/grade")
            .then(response => {
                return this.setState({
                    grades: response.data.value,
                    gradeSelected: response.data.value[0].id
                }, this.getInCourseEssayList)
            })
    }

    getTextbookVersionList() {
        service.get("/api/textbook-version")
            .then(response => {
                return this.setState({
                    textbookVersions: response.data.value,
                    textbookVersionSelected: response.data.value[0].id
                }, this.getGradeList)
            })
    }

    getInCourseEssayList() {
        service.get("/api/essay", {
            params: {
                type: 0,
                textbookVersion: this.state.textbookVersionSelected,
                grade: this.state.gradeSelected
            }
        }).then(response => {
            this.setState({
                essays: response.data.value.map((value, index) => {
                    value.key = index
                    return value
                })
            })
        })
    }

    onGradeClicked(gradeSelected) {
        if (gradeSelected === this.state.gradeSelected) {
            return
        }
        this.setState({
            gradeSelected: gradeSelected
        }, this.getInCourseEssayList)
    }

    onTextbookVersionClicked(textbookVersionSelected) {
        if (textbookVersionSelected === this.state.textbookVersionSelected) {
            return
        }
        this.setState({
            textbookVersionSelected: textbookVersionSelected
        }, this.getInCourseEssayList)
    }
}



export default InCourseHomework;
