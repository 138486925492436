import {Input, Space, Tag, Tooltip} from "antd";
import {CloseOutlined, SaveOutlined, AudioOutlined, StarOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

const {TextArea} = Input;
const { CheckableTag } = Tag

const ParagraphEvaluation = ({ paragraphCount, evaluation}) => {
    const [comment, setComment] = useState(evaluation.content)  //使用setComment函数更新comment
    const [selectedTags, setSelectedTags] = useState(evaluation.tags);


    const userInfoJson = sessionStorage.getItem("userInfo")
    const isTeacher = JSON.parse(userInfoJson).role.split(",").includes("teacher")

    const tagsData = (evaluation) => {
        switch(evaluation.paragraphIndex) {
            case 0:
                return ['开门见山', '揭示中心', '总领全文'];
            case paragraphCount - 1:
                return ['首尾呼应', '篇末点题', '总结全文', '深化主旨'];
            default:
                return ['承上启下', '详略得当', '生动具体'];
        }
    }

    const tagSelectionChangeHandler = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
        evaluation.tags = nextSelectedTags
    };

    useEffect(() => {
        setComment(evaluation.content)
        setSelectedTags(evaluation.tags)
    }, [evaluation])

    const inputChangeHandler = event => {
        setComment(event.target.value)
        evaluation.content = event.target.value
    }

    return (
        <div className="card-container card-border" style={{marginBottom: "0.5em", backgroundColor: "white", padding: "0.5em 1em"}}>
            <div style={{width: "100%", color: "grey"}}>
                第{evaluation.paragraphIndex + 1}段段评
            </div>

            <Space size={[0, 8]} wrap>
                {tagsData(evaluation).map((tag) => (
                <CheckableTag
                    key={tag}
                    checked={selectedTags.includes(tag)}
                    onChange={(checked) => isTeacher && tagSelectionChangeHandler(tag, checked)}
                    style={{border: '1px solid #d9d9d9', borderRadius: '4px'}}
                >
                    <Tooltip title="Starred">
                        <StarOutlined style={{marginRight: '8px'}} />
                    </Tooltip>
                    {tag}
                </CheckableTag>
                ))}
            </Space>

            <div style={{position: 'relative', width: "100%", marginTop: "0.5em"}}>
                {
                    isTeacher ? (
                        <>
                            <TextArea rows={4} value={comment} onChange={inputChangeHandler} allowClear />
                            <AudioOutlined style={{position: 'absolute', bottom: '10px', right: '10px', zIndex: 1}} />
                        </>
                    ) : (
                        <span>{comment}</span>
                    )
                }
            </div>
        </div>
    )
}

export default ParagraphEvaluation;

